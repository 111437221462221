// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Button, Progress, Alert, Tooltip, Switch, Spin } from 'antd';
// import { bindAll } from 'lodash';
// import ls from 'local-storage';
// import ImageUploader from '../helpers/ImageUploader';
// import { ReactComponent as DragDropSVG } from '../svg/DragDropIcon.svg';
// import { CloudUpload } from 'lucide-react';

// const SUPPORTED_IMAGES = ['image/jpg', 'image/jpeg'];

// class UploadComp extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			isMobile: window.screen.width <= 576,
// 			isProcessing: false
// 		};
// 		bindAll(this, ['startUpload', 'handleFile', 'drop', 'allowDrop', 'handleWindowResize']);
// 		this.uploader = new ImageUploader();
// 	}

// 	componentDidMount() {
// 		window.addEventListener('resize', this.handleWindowResize);
// 	}

// 	componentWillUnmount() {
// 		window.removeEventListener('resize', this.handleWindowResize);
// 	}

// 	handleWindowResize = async () => {
// 		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
// 		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
// 	};

// 	UNSAFE_componentWillMount() {
// 		const { photosUploaded, uploadProgressUpdated, uploadFinished, uploadError } = this.props;
// 		var that = this;
// 		this.uploader.setOnProgressListener((status, pending, info) => {
// 			console.log('setOnProgressListener: ' + status);
// 			if (status === 'active') {
// 				uploadProgressUpdated(pending);
// 				return;
// 			}

// 			console.timeEnd('UPLOAD TOTAL TIME');
// 			photosUploaded(that.props.event, that.props.album);
// 			if (status === 'complete') {
// 				uploadFinished(info);
// 				setTimeout(() => {
// 					uploadFinished(undefined);
// 				}, 10000);
// 				return;
// 			}

// 			console.log(info);
// 			uploadError(info);
// 		});
// 	}

// 	drop = (e) => {
// 		e.preventDefault();
// 		this.setState({ isProcessing: true });
// 		console.log('starting image processing...');
// 		const { uploadError } = this.props;
// 		var fileArray = [];
// 		var cursor = 0;
// 		let files = e.dataTransfer.files;
// 		for (; cursor < files.length; ++cursor) {
// 			let file = files[cursor];
// 			if (SUPPORTED_IMAGES.indexOf(file.type) === -1) {
// 				uploadError('Only jpegs images are supported.');
// 				return;
// 			}
// 			fileArray.push(file);
// 		}
// 		this.startUpload(fileArray);
// 	};

// 	allowDrop = (e) => {
// 		e.preventDefault();
// 	};

// 	handleFile(e) {
// 		e.preventDefault();
// 		console.log('starting image processing...');
// 		this.setState({ isProcessing: true });
// 		var fileArray = [];
// 		var cursor = 0;
// 		for (; cursor < e.target.files.length; ++cursor) {
// 			fileArray.push(e.target.files[cursor]);
// 		}
// 		this.startUpload(fileArray);
// 		e.target.value = null;
// 	}

// 	startUpload = (fileArray) => {
// 		const { userId, event, album, uploadStarted, uploadError, eventPhotoCount } = this.props;
// 		if (fileArray.length > 2000) {
// 			uploadError('Maximum 2000 images can be uploaded at once');
// 			return;
// 		}
// 		uploadStarted(fileArray.length);
// 		this.uploader.setConfig({
// 			quality: 0.8,
// 			autoRotate: true,
// 			debug: false,
// 			userId: userId,
// 			event: event,
// 			album: album,
// 			eventPhotoCount: eventPhotoCount
// 		});
// 		this.uploader.isCancelled = false;
// 		this.uploader.uploadFileFromFileArray(fileArray).finally(() => {
// 			console.log('image processing finished...');
// 			this.setState({ isProcessing: false });
// 		});
// 	};

// 	isDragNDropSupported = () => {
// 		var div = document.createElement('div');
// 		return (
// 			('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
// 			'FormData' in window &&
// 			'FileReader' in window
// 		);
// 	};

// 	render() {
// 		const { successMessage, uploading, pending, total, error } = this.props;
// 		let header, footer;

// 		const isDragNDropSupported = this.isDragNDropSupported();

// 		let messageElement = null;

// 		if (uploading) {
// 			let progress = Math.trunc(((total - pending) / total) * 100);
// 			let progressLabel = 'Uploading ' + pending + ' photos';
// 			let progressLabelOnProgressBar = total - pending + '/' + total;

// 			if (pending === 0) {
// 				progressLabel = 'Completing Uploads..';
// 			}

// 			if (progress < 10) {
// 				progress = 10;
// 			}
// 			header = (
// 				<div>
// 					<p>{progressLabel}</p>
// 				</div>
// 			);
// 			footer = (
// 				<div className="upload-progress">
// 					<Tooltip className="upload-progress-bar" title={progressLabelOnProgressBar} sty>
// 						<Progress percent={progress} status="active" />
// 					</Tooltip>
// 					<Button
// 						className="upload-progress-cancel-button"
// 						onClick={(e) => {
// 							e.preventDefault();
// 							this.uploader.cancel();
// 						}}>
// 						Cancel
// 					</Button>
// 				</div>
// 			);
// 		} else {
// 			if (successMessage) {
// 				messageElement = (
// 					<Alert
// 						type="success"
// 						message={successMessage}
// 						style={{ marginTop: '10px' }}
// 						closable
// 						showIcon
// 					/>
// 				);
// 			}

// 			header = (
// 				<div>
// 					{isDragNDropSupported && !this.state.isProcessing ? (
// 						<div>
// 							<CloudUpload style={{ margin: '10px' }} size={36} />
// 							<h3 style={{ marginBottom: '15px' }}>
// 								<strong>Drag and drop your images here!</strong>
// 							</h3>
// 							<p></p>
// 							<h5>or</h5>
// 						</div>
// 					) : null}
// 				</div>
// 			);

// 			footer = this.state.isMobile ? (
// 				<div
// 					style={{ width: '100%', padding: '10px' }}
// 					role="button"
// 					onClick={(e) => {
// 						document.getElementById('fileUploadInputTypeFile').click();
// 						document.activeElement.blur();
// 					}}>
// 					<Spin spinning={this.state.isProcessing}>
// 						<input
// 							type="file"
// 							id="fileUploadInputTypeFile"
// 							onChange={this.handleFile}
// 							multiple="multiple"
// 							accept="image/.jpeg,image/.jpg"
// 							style={{ display: 'none' }}
// 						/>
// 					</Spin>

// 					{this.state.isProcessing ? (
// 						<Spin />
// 					) : (
// 						<div
// 							style={{
// 								display: 'flex',
// 								alignItems: 'center',
// 								backgroundColor: '#F3F3F3',
// 								padding: '10px',
// 								borderRadius: '6px',
// 								width: '100%'
// 							}}>
// 							<DragDropSVG style={{ marginRight: '10px' }} />
// 							<h3
// 								style={{
// 									margin: 0,
// 									fontSize: '22px',
// 									color: '#4A4A4A',
// 									textAlign: 'center',
// 									fontWeight: 600
// 								}}>
// 								Upload Photos
// 							</h3>
// 						</div>
// 					)}
// 				</div>
// 			) : (
// 				<div>
// 					<input
// 						type="file"
// 						id="fileUploadInputTypeFile"
// 						onChange={this.handleFile}
// 						multiple="multiple"
// 						accept="image/jpeg,image/jpg"
// 						style={{ display: 'none' }}
// 					/>
// 					{this.state.isProcessing ? (
// 						<Spin />
// 					) : (
// 						<>
// 							<Button
// 								className="btn-gray"
// 								style={{ margin: '15px', marginBottom: '20px', width: '200px' }}
// 								onClick={(e) => {
// 									e.preventDefault();
// 									document.getElementById('fileUploadInputTypeFile').click();
// 									document.activeElement.blur();
// 								}}>
// 								Browse to Upload
// 							</Button>
// 							<p style={{ marginBottom: '5px' }}>Maximum 2000 images can be uploaded at once</p>
// 						</>
// 					)}
// 				</div>
// 			);
// 		}

// 		if (error) {
// 			const { uploadError } = this.props;
// 			messageElement = (
// 				<Alert
// 					type="error"
// 					message={`Upload Error! ${error}`}
// 					style={{ marginTop: '10px' }}
// 					closable
// 					showIcon
// 					afterClose={() => uploadError(undefined)}
// 				/>
// 			);
// 		}

// 		if (isDragNDropSupported && uploading === false && !this.state.isProcessing) {
// 			if (!ls.get('addWatermarkDuringUpload')) {
// 				ls.get('addWatermarkDuringUpload', '1');
// 			}
// 			let addWatermark = ls.get('addWatermarkDuringUpload');

// 			return (
// 				<div className="uploadContainerWithDragDrop" onDrop={this.drop} onDragOver={this.allowDrop}>
// 					{messageElement}
// 					<div className="switchHolder upload-container-watermark-option">
// 						{this.state.isMobile ? (
// 							<h4
// 								style={{
// 									display: 'inline',
// 									color: '#111111DE',
// 									fontWeight: 600,
// 									fontSize: '20px'
// 								}}>
// 								Watermark
// 							</h4>
// 						) : (
// 							<h4 style={{ display: 'inline' }}>Add Watermark?</h4>
// 						)}
// 						<Switch
// 							checked={addWatermark === '0' ? false : true}
// 							onChange={(e) => {
// 								ls.set('addWatermarkDuringUpload', addWatermark === '0' ? '1' : '0');
// 								this.forceUpdate();
// 							}}
// 						/>
// 					</div>
// 					{!this.state.isMobile && header}
// 					{footer}
// 				</div>
// 			);
// 		}

// 		return (
// 			<div className="uploadContainerWithDragDrop">
// 				{messageElement}
// 				{header}
// 				{footer}
// 			</div>
// 		);
// 	}
// }

// UploadComp.propTypes = {
// 	uploading: PropTypes.bool.isRequired,
// 	pending: PropTypes.number.isRequired,
// 	total: PropTypes.number.isRequired,
// 	error: PropTypes.string,
// 	userId: PropTypes.string.isRequired,
// 	event: PropTypes.object.isRequired,
// 	eventPhotoCount: PropTypes.number,
// 	album: PropTypes.object.isRequired,
// 	uploadStarted: PropTypes.func.isRequired,
// 	uploadFinished: PropTypes.func.isRequired,
// 	uploadProgressUpdated: PropTypes.func.isRequired,
// 	uploadError: PropTypes.func.isRequired,
// 	successMessage: PropTypes.string,
// 	photosUploaded: PropTypes.func.isRequired
// };

// export default UploadComp;

import { Alert, Button, Progress, Space, Spin, Switch, Tooltip, Typography } from 'antd';
import ls from 'local-storage';
import { bindAll } from 'lodash';
import { CloudUpload } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { refreshSubscriptionDetails } from '../actions/subscriptionActions';
import ImageUploader from '../helpers/ImageUploader';
import { ReactComponent as DragDropSVG } from '../svg/DragDropIcon.svg';
import { calculateActualUploadLimit, formatCount } from '../utils/subscriptionUtils';

const { Text } = Typography;
const SUPPORTED_IMAGES = ['image/jpg', 'image/jpeg'];
const MAX_UPLOAD_COUNT = 2000;

class UploadComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576,
			isProcessing: false,
			remainingUploadLimit: 0
		};
		bindAll(this, [
			'startUpload',
			'handleFile',
			'drop',
			'allowDrop',
			'handleWindowResize',
			'updateUploadLimit'
		]);
		this.uploader = new ImageUploader();
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
		this.updateUploadLimit();
	}

	componentDidUpdate(prevProps) {
		const shouldUpdateLimit =
			prevProps.subscription !== this.props.subscription ||
			prevProps.event?.eventType !== this.props.event?.eventType ||
			prevProps.eventPhotoCount !== this.props.eventPhotoCount ||
			(prevProps.uploading && !this.props.uploading);

		if (shouldUpdateLimit) {
			console.log('Updating limits due to props change:', {
				subscriptionChanged: prevProps.subscription !== this.props.subscription,
				eventTypeChanged: prevProps.event?.eventType !== this.props.event?.eventType,
				photoCountChanged: prevProps.eventPhotoCount !== this.props.eventPhotoCount,
				uploadCompleted: prevProps.uploading && !this.props.uploading
			});
			this.updateUploadLimit();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	updateUploadLimit() {
		const { event, subscription, eventPhotoCount } = this.props;

		console.log('Updating upload limits:', {
			eventType: event?.eventType,
			maxPhotos: event?.maxPhotos,
			currentPhotoCount: eventPhotoCount,
			guestMaxPhotos: event?.guestMaxPhotos
		});

		if (event?.eventType === 1 && subscription) {
			const actualLimit = calculateActualUploadLimit(subscription);
			console.log('Updating subscription-based limit:', {
				actualLimit,
				subscription: {
					uploadLimit: subscription.uploadLimit,
					uploadedPhotosCount: subscription.uploadedPhotosCount,
					maxPhotosLimit: subscription.maxPhotosLimit
				}
			});
			this.setState({ remainingUploadLimit: actualLimit });
		} else {
			const maxPhotos = event.maxPhotos - (event.guestMaxPhotos || 0);
			const remaining = maxPhotos - eventPhotoCount;
			console.log('Updating pack-based limit:', {
				maxPhotos,
				eventPhotoCount,
				remaining
			});
			this.setState({ remainingUploadLimit: remaining });
		}
	}

	handleWindowResize = async () => {
		const isMobile = window.innerWidth <= 576;
		if (isMobile !== this.state.isMobile) {
			this.setState({ isMobile });
		}
	};

	updateVirtualLimit = (uploadedCount) => {
		this.setState((prevState) => ({
			virtualUploadCount: prevState.virtualUploadCount + uploadedCount
		}));
	};

	handleWindowResize = async () => {
		const isMobile = window.innerWidth <= 576;
		if (isMobile !== this.state.isMobile) {
			this.setState({ isMobile });
		}
	};

	UNSAFE_componentWillMount() {
		const { photosUploaded, uploadProgressUpdated, uploadFinished, uploadError, dispatch } =
			this.props;
		this.uploader.setOnProgressListener(async (status, pending, info) => {
			console.log('Upload Progress:', {
				status,
				pending,
				total: this.props.total
			});

			if (status === 'active') {
				uploadProgressUpdated(pending);
				return;
			}

			console.timeEnd('UPLOAD TOTAL TIME');

			if (status === 'complete') {
				try {
					// First update the photos count
					photosUploaded(this.props.event, this.props.album);

					// For subscription events, refresh subscription details
					if (this.props.event?.eventType === 1) {
						console.log('Refreshing subscription after upload completion');
						await dispatch(refreshSubscriptionDetails());
					}

					uploadFinished(info);
					setTimeout(() => uploadFinished(undefined), 10000);
				} catch (error) {
					console.error('Error updating counts after upload:', error);
					uploadError('Failed to update photo counts');
				}
				return;
			}

			uploadError(info);
		});
	}

	// validateSubscriptionLimits = (fileCount) => {
	// 	const { subscription, event } = this.props;

	// 	// First check event type
	// 	console.log('Validating upload limits:', {
	// 		eventType: event?.eventType,
	// 		isSubscriptionEvent: event?.eventType === 1
	// 	});

	// 	// Only check subscription for type 1 events
	// 	if (!event?.eventType || event.eventType !== 1) {
	// 		console.log('Regular event - skipping subscription validation');
	// 		return { valid: true };
	// 	}

	// 	console.log('Subscription event - checking limits:', {
	// 		subscription: subscription
	// 			? {
	// 					status: subscription.status,
	// 					uploadLimit: subscription.uploadLimit,
	// 					currentUploads: subscription.uploadedPhotosCount,
	// 					maxPhotosLimit: subscription.maxPhotosLimit,
	// 					currentPhotos: subscription.currentPhotosCount,
	// 					expiresAt: subscription.expiresAt
	// 			  }
	// 			: 'No subscription'
	// 	});

	// 	if (!subscription) {
	// 		console.log('No subscription found for subscription event');
	// 		return { valid: false, message: 'No active subscription found' };
	// 	}

	// 	const now = new Date();
	// 	const expiryDate = new Date(subscription.expiresAt);

	// 	if (subscription.status !== 'active' || expiryDate <= now) {
	// 		console.log('Subscription validation failed:', {
	// 			status: subscription.status,
	// 			expiryDate: subscription.expiresAt,
	// 			isExpired: expiryDate <= now
	// 		});
	// 		return { valid: false, message: 'Subscription is not active or has expired' };
	// 	}

	// 	const remainingUploads = subscription.uploadLimit - subscription.uploadedPhotosCount;
	// 	const remainingStorage = subscription.maxPhotosLimit - subscription.currentPhotosCount;

	// 	console.log('Checking upload limits:', {
	// 		requestedUpload: fileCount,
	// 		remainingUploads,
	// 		remainingStorage
	// 	});

	// 	if (fileCount > remainingUploads) {
	// 		return {
	// 			valid: false,
	// 			message: `Upload limit exceeded. You can upload ${remainingUploads} more photos.`
	// 		};
	// 	}

	// 	if (fileCount > remainingStorage) {
	// 		return {
	// 			valid: false,
	// 			message: `Storage limit exceeded. You can store ${remainingStorage} more photos.`
	// 		};
	// 	}

	// 	console.log('Subscription validation passed');
	// 	return { valid: true };
	// };

	validateSubscriptionLimits = (fileCount) => {
		const { event, eventPhotoCount } = this.props;
		const { remainingUploadLimit } = this.state;

		console.log('Validating upload limits:', {
			eventType: event?.eventType,
			fileCount,
			remainingUploadLimit,
			maxPhotos: event?.maxPhotos,
			currentEventPhotoCount: eventPhotoCount,
			guestMaxPhotos: event?.guestMaxPhotos
		});

		// For non-subscription events (type 0), check against maxPhotos
		if (event?.eventType === 0) {
			const maxPhotos = event.maxPhotos - (event.guestMaxPhotos || 0);
			const remaining = maxPhotos - (eventPhotoCount || 0);

			console.log('Pack-based limit check:', {
				maxPhotos,
				eventPhotoCount,
				remaining,
				attemptingToUpload: fileCount
			});

			if (fileCount > remaining) {
				return {
					valid: false,
					message: `Upload limit exceeded. You can only upload ${remaining} more photo${
						remaining === 1 ? '' : 's'
					}.`
				};
			}
			return { valid: true };
		}

		// Rest of the subscription validation logic...
		if (fileCount > remainingUploadLimit) {
			return {
				valid: false,
				message: `Upload limit exceeded. You can upload ${remainingUploadLimit} more photo${
					remainingUploadLimit === 1 ? '' : 's'
				}.`
			};
		}

		return { valid: true };
	};

	drop = (e) => {
		e.preventDefault();
		this.setState({ isProcessing: true });

		const { uploadError } = this.props;
		const files = Array.from(e.dataTransfer.files);

		// Validate file types
		const invalidFiles = files.filter((file) => !SUPPORTED_IMAGES.includes(file.type));
		if (invalidFiles.length > 0) {
			uploadError('Only JPEG images are supported.');
			this.setState({ isProcessing: false });
			return;
		}

		this.startUpload(files);
	};

	allowDrop = (e) => {
		e.preventDefault();
	};

	handleFile = (e) => {
		e.preventDefault();
		this.setState({ isProcessing: true });
		const files = Array.from(e.target.files);
		this.startUpload(files);
		e.target.value = null;
	};

	startUpload = (fileArray) => {
		const { userId, event, album, uploadStarted, uploadError, eventPhotoCount, subscription } =
			this.props;

		console.log('Starting upload validation:', {
			eventId: event?.id,
			eventType: event?.eventType,
			fileCount: fileArray.length,
			eventPhotoCount,
			maxPhotos: event?.maxPhotos,
			guestMaxPhotos: event?.guestMaxPhotos,
			remainingLimit: this.state.remainingUploadLimit
		});

		// Validate file count
		if (fileArray.length > MAX_UPLOAD_COUNT) {
			uploadError(`Maximum ${MAX_UPLOAD_COUNT} images can be uploaded at once`);
			this.setState({ isProcessing: false });
			return;
		}

		const validationResult = this.validateSubscriptionLimits(fileArray.length);
		if (!validationResult.valid) {
			console.log('Upload validation failed:', validationResult);
			uploadError(validationResult.message);
			this.setState({ isProcessing: false });
			return;
		}

		console.log('Upload validation passed, proceeding with upload');
		uploadStarted(fileArray.length);

		this.uploader.setConfig({
			quality: 0.8,
			autoRotate: true,
			debug: false,
			userId,
			event,
			album,
			eventPhotoCount,
			subscription: event?.eventType === 1 ? subscription : undefined // Only pass subscription for type 1 events
		});

		console.log('Starting file upload with config:', {
			eventId: event.id,
			eventType: event.eventType,
			albumId: album.id,
			fileCount: fileArray.length,
			hasSubscription: !!subscription
		});

		this.uploader.isCancelled = false;
		this.uploader.uploadFileFromFileArray(fileArray).finally(() => {
			console.log('Upload process finished');
			this.setState({ isProcessing: false });
		});
	};

	isDragNDropSupported = () => {
		var div = document.createElement('div');
		return (
			('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
			'FormData' in window &&
			'FileReader' in window
		);
	};

	renderLimitMessage() {
		const { event } = this.props;
		const { remainingUploadLimit } = this.state;

		if (remainingUploadLimit > 0) {
			return null;
		}

		if (event?.eventType === 1) {
			return (
				<Alert
					type="warning"
					message="Upload Limit Reached"
					description="You have reached your subscription upload limit. Please contact support to upgrade your subscription for additional capacity."
					showIcon
					style={{ marginBottom: '15px' }}
				/>
			);
		} else if (event?.eventType === 0) {
			return (
				<Alert
					type="warning"
					message="Photo Limit Reached"
					description="You have reached the maximum photo limit for this event. Please increase the photo limit to continue uploading."
					showIcon
					style={{ marginBottom: '15px' }}
				/>
			);
		}

		return null;
	}

	renderLimits = () => {
		const { event } = this.props;
		const { remainingUploadLimit } = this.state;

		return (
			<Space direction="vertical" size="small" className="upload-limits" style={{ width: '100%' }}>
				{this.renderLimitMessage()}
				<Text>Available Upload Capacity: {formatCount(remainingUploadLimit)} photos</Text>
			</Space>
		);
	};

	render() {
		const { successMessage, uploading, pending, total, error } = this.props;
		const isDragNDropSupported = this.isDragNDropSupported();
		const isUploadLimitReached = this.state.remainingUploadLimit <= 0;
		let header, footer;
		let messageElement = null;

		if (uploading) {
			const progress = Math.max(10, Math.trunc(((total - pending) / total) * 100));
			const progressLabel = pending === 0 ? 'Completing Uploads..' : `Uploading ${pending} photos`;
			const progressBarLabel = `${total - pending}/${total}`;

			header = (
				<div>
					<p>{progressLabel}</p>
				</div>
			);
			footer = (
				<div className="upload-progress">
					<Tooltip className="upload-progress-bar" title={progressBarLabel}>
						<Progress percent={progress} status="active" />
					</Tooltip>
					<Button
						className="upload-progress-cancel-button"
						onClick={(e) => {
							e.preventDefault();
							this.uploader.cancel();
						}}>
						Cancel
					</Button>
				</div>
			);
		} else {
			if (successMessage) {
				messageElement = (
					<Alert
						type="success"
						message={successMessage}
						style={{ marginTop: '10px' }}
						closable
						showIcon
					/>
				);
			}

			header = (
				<div>
					{isDragNDropSupported && !this.state.isProcessing && (
						<div className="upload-content">
							<CloudUpload style={{ margin: '10px' }} size={36} />
							<h3 style={{ marginBottom: '15px' }}>
								<strong>Drag and drop your images here!</strong>
							</h3>
							{this.renderLimits()}
						</div>
					)}
				</div>
			);

			footer =
				!isUploadLimitReached &&
				(this.state.isMobile ? (
					<div
						className="mobile-upload-container"
						role="button"
						onClick={() => document.getElementById('fileUploadInputTypeFile').click()}>
						<Spin spinning={this.state.isProcessing}>
							<input
								type="file"
								id="fileUploadInputTypeFile"
								onChange={this.handleFile}
								multiple="multiple"
								accept="image/jpeg,image/jpg"
								style={{ display: 'none' }}
							/>
							{!this.state.isProcessing && (
								<div className="mobile-upload-button">
									<DragDropSVG style={{ marginRight: '10px' }} />
									<div className="mobile-upload-content">
										<h3>Upload Photos</h3>
									</div>
								</div>
							)}
						</Spin>
					</div>
				) : (
					<div>
						<input
							type="file"
							id="fileUploadInputTypeFile"
							onChange={this.handleFile}
							multiple="multiple"
							accept="image/jpeg,image/jpg"
							style={{ display: 'none' }}
						/>
						{this.state.isProcessing ? (
							<Spin />
						) : (
							<>
								<Button
									className="btn-gray"
									style={{ margin: '15px', marginBottom: '20px', width: '200px' }}
									onClick={() => document.getElementById('fileUploadInputTypeFile').click()}>
									Browse to Upload
								</Button>
								<p style={{ marginBottom: '5px' }}>
									Maximum {MAX_UPLOAD_COUNT} images can be uploaded at once
								</p>
							</>
						)}
					</div>
				));
		}

		if (error) {
			const { uploadError } = this.props;
			messageElement = (
				<Alert
					type="error"
					message={`Upload Error! ${error}`}
					style={{ marginTop: '10px' }}
					closable
					showIcon
					afterClose={() => uploadError(undefined)}
				/>
			);
		}

		if (isDragNDropSupported && !uploading && !this.state.isProcessing) {
			const addWatermark = ls.get('addWatermarkDuringUpload') || '1';

			return (
				<div
					className={`uploadContainerWithDragDrop ${isUploadLimitReached ? 'no-drop' : ''}`}
					onDrop={!isUploadLimitReached ? this.drop : null}
					onDragOver={!isUploadLimitReached ? this.allowDrop : null}>
					{messageElement}
					<div className="switchHolder upload-container-watermark-option">
						<h4
							style={
								this.state.isMobile
									? {
											display: 'inline',
											color: '#111111DE',
											fontWeight: 600,
											fontSize: '20px'
									  }
									: { display: 'inline' }
							}>
							{this.state.isMobile ? 'Watermark' : 'Add Watermark?'}
						</h4>
						<Switch
							checked={addWatermark !== '0'}
							onChange={() => {
								ls.set('addWatermarkDuringUpload', addWatermark === '0' ? '1' : '0');
								this.forceUpdate();
							}}
						/>
					</div>
					{!this.state.isMobile && header}
					{!isUploadLimitReached && footer}
				</div>
			);
		}

		return (
			<div className="uploadContainerWithDragDrop">
				{messageElement}
				{header}
				{!isUploadLimitReached && footer}
			</div>
		);
	}
}

UploadComp.propTypes = {
	uploading: PropTypes.bool.isRequired,
	pending: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	error: PropTypes.string,
	userId: PropTypes.string.isRequired,
	event: PropTypes.shape({
		id: PropTypes.string.isRequired,
		eventType: PropTypes.number,
		maxPhotos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		guestMaxPhotos: PropTypes.number
	}).isRequired,
	eventPhotoCount: PropTypes.number,
	album: PropTypes.object.isRequired,
	uploadStarted: PropTypes.func.isRequired,
	uploadFinished: PropTypes.func.isRequired,
	uploadProgressUpdated: PropTypes.func.isRequired,
	uploadError: PropTypes.func.isRequired,
	successMessage: PropTypes.string,
	photosUploaded: PropTypes.func.isRequired,
	subscription: PropTypes.shape({
		id: PropTypes.string,
		status: PropTypes.string,
		expiresAt: PropTypes.string,
		uploadLimit: PropTypes.number,
		maxPhotosLimit: PropTypes.number,
		uploadedPhotosCount: PropTypes.number,
		currentPhotosCount: PropTypes.number,
		guestPhotosLimit: PropTypes.number
	}),
	dispatch: PropTypes.func.isRequired
};

export default UploadComp;
