import { UploadOutlined } from '@ant-design/icons';
import { Button, ColorPicker, Switch, Upload, message } from 'antd';
import QRCodeStyling from 'qr-code-styling';
import React, { useRef, useState } from 'react';
import defaultLogoImage from '../svg/kamero_logo.svg';

const MAX_LOGO_SIZE = 80 * 1024; // 80 KB
const defaultQRColor = '#6F4898';

const FollowQRGenerator = ({ data }) => {
	const [qrCodeDataURL, setQrCodeDataURL] = useState('');
	const qrCodeRef = useRef(null);
	const [logoSrc, setLogoSrc] = useState(defaultLogoImage);
	const [qrColor, setQrColor] = useState(defaultQRColor);
	const [showLogo, setShowLogo] = useState(true);
	const [customLogoFile, setCustomLogoFile] = useState(null);

	const handleCustomLogoUpload = (file) => {
		if (file.size > MAX_LOGO_SIZE) {
			message.error('Image size must be under 80 KB. Please compress and try again.');
			return false;
		}
		const reader = new FileReader();
		reader.onload = () => {
			setLogoSrc(reader.result);
			setCustomLogoFile(file);
			setShowLogo(true);
		};
		reader.readAsDataURL(file);
		return false;
	};

	const generateQRCode = async (options = {}) => {
		const { width = 250, height = 250, download = false, extension } = options;

		const qrCode = new QRCodeStyling({
			width,
			height,
			data: data,
			image: showLogo ? logoSrc : undefined,
			dotsOptions: {
				color: qrColor,
				type: 'dots'
			},
			cornersSquareOptions: {
				type: 'extra-rounded'
			},
			imageOptions: {
				crossOrigin: 'anonymous',
				imageSize: 0.5,
				margin: 3.5
			},
			qrOptions: {
				errorCorrectionLevel: 'H'
			},
			backgroundOptions: {
				color: '#ffffff'
			}
		});

		if (download) {
			qrCode.download({
				name: 'Follow_All_Events_QR',
				extension: extension
			});
		} else {
			const blob = await qrCode.getRawData();
			const url = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(blob);
			});
			setQrCodeDataURL(url);
		}
	};

	React.useEffect(() => {
		if (data) {
			generateQRCode();
		}
	}, [data, logoSrc, qrColor, showLogo]);

	const containerStyle = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '24px'
	};

	const qrDisplayStyle = {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '12px'
	};

	const controlsStyle = {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '24px'
	};

	const uploadButtonStyle = {
		width: '100%',
		maxWidth: '200px',
		height: '40px',
		borderRadius: '8px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '8px'
	};

	const settingsRowStyle = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '12px 0',
		borderTop: '1px solid #f0f0f0',
		borderBottom: '1px solid #f0f0f0'
	};

	const downloadButtonsStyle = {
		display: 'flex',
		gap: '12px'
	};

	return (
		<div style={containerStyle}>
			{/* QR Display */}
			<div style={qrDisplayStyle}>
				{qrCodeDataURL && (
					<img src={qrCodeDataURL} alt="QR Code" style={{ width: '250px', height: '250px' }} />
				)}
			</div>

			<div style={controlsStyle}>
				{/* Centered Upload Button */}
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Upload accept="image/*" beforeUpload={handleCustomLogoUpload} showUploadList={false}>
						<Button style={uploadButtonStyle}>
							<UploadOutlined />
							Upload Logo
						</Button>
					</Upload>
				</div>

				{/* Settings Row */}
				<div style={settingsRowStyle}>
					<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						<span>QR Color:</span>
						<ColorPicker value={qrColor} onChange={(color) => setQrColor(color)} />
					</div>
					<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						<span>Show Logo:</span>
						<Switch checked={showLogo} onChange={setShowLogo} />
					</div>
				</div>

				{/* Download Buttons */}
				<div style={downloadButtonsStyle}>
					<Button
						style={{ flex: 1 }}
						onClick={() =>
							generateQRCode({ width: 4096, height: 4096, download: true, extension: 'png' })
						}>
						Download PNG
					</Button>
					<Button
						style={{ flex: 1 }}
						onClick={() =>
							generateQRCode({ width: 1024, height: 1024, download: true, extension: 'svg' })
						}>
						Download SVG
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FollowQRGenerator;
