import { Button, Modal, Progress } from 'antd';
import { ImagePlus, Upload } from 'lucide-react';

const ThumbnailModal = ({
	open,
	setOpen,
	ThumbnailUploadClick,
	handleThumbnailChange,
	ThumbnailPhotInputRef,
	clickedLink,
	setClickedLink,
	videoLinkThumbnailEdited,
	visibleEvent,
	openNotificationWithIcon,
	setOpenPreviewModal,
	uploadProgress
}) => {
	const styles = {
		modalContent: {
			padding: '20px 0'
		},
		titleWrapper: {
			textAlign: 'center',
			marginBottom: '24px'
		},
		title: {
			color: '#1F2937',
			fontSize: '18px',
			fontWeight: 600,
			margin: 0
		},
		uploadSection: {
			background: '#F9FAFB',
			border: '2px dashed #E5E7EB',
			borderRadius: '8px',
			padding: '24px',
			textAlign: 'center',
			cursor: 'pointer',
			transition: 'all 0.2s',
			marginBottom: '20px',
			'&:hover': {
				borderColor: '#6F4898',
				background: '#F3F4F6'
			}
		},
		uploadIcon: {
			marginBottom: '12px',
			color: '#6F4898'
		},
		uploadText: {
			color: '#4B5563',
			fontWeight: 500,
			margin: 0
		},
		progressBar: {
			marginBottom: '20px'
		},
		previewContainer: {
			position: 'relative',
			width: '100%',
			height: '240px',
			backgroundColor: '#F3F4F6',
			borderRadius: '8px',
			overflow: 'hidden',
			marginBottom: '20px'
		},
		previewImage: {
			width: '100%',
			height: '100%',
			objectFit: 'cover'
		},
		previewButton: {
			position: 'absolute',
			right: '12px',
			bottom: '12px',
			background: 'white',
			borderRadius: '50%',
			padding: '8px',
			boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			border: 'none'
		},
		saveButton: {
			width: '100%',
			height: '40px',
			backgroundColor: '#6F4898',
			borderColor: '#6F4898',
			boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
		}
	};

	return (
		<Modal
			width={480}
			open={open}
			onCancel={() => {
				setOpen(false);
				setClickedLink({});
			}}
			footer={null}
			centered>
			<div style={styles.modalContent}>
				<div style={styles.titleWrapper}>
					<h4 style={styles.title}>Upload Thumbnail Image</h4>
				</div>

				<div style={styles.uploadSection} onClick={ThumbnailUploadClick}>
					<Upload size={32} style={styles.uploadIcon} color="#6F4898" />
					<p style={styles.uploadText}>Click to upload thumbnail</p>
					<input
						type="file"
						ref={ThumbnailPhotInputRef}
						style={{ display: 'none' }}
						onChange={handleThumbnailChange}
						accept="image/*"
					/>
				</div>

				{uploadProgress > 0 && (
					<div style={styles.progressBar}>
						<Progress percent={uploadProgress} strokeColor="#6F4898" size="small" />
					</div>
				)}

				{clickedLink.thumbnailURL && (
					<div style={styles.previewContainer}>
						<img
							src={clickedLink.thumbnailURL}
							style={styles.previewImage}
							alt="Thumbnail preview"
						/>
						<button
							style={styles.previewButton}
							onClick={() => setOpenPreviewModal(true)}
							title="Preview image">
							<ImagePlus size={20} color="#6F4898" />
						</button>
					</div>
				)}

				<Button
					type="primary"
					style={styles.saveButton}
					disabled={!clickedLink.thumbnailURL}
					onClick={() => {
						videoLinkThumbnailEdited(
							clickedLink.index,
							visibleEvent,
							clickedLink.name,
							clickedLink,
							clickedLink.thumbnailURL,
							openNotificationWithIcon
						);
						setOpen(false);
					}}>
					Save Changes
				</Button>
			</div>
		</Modal>
	);
};

export default ThumbnailModal;
