import { Menu, Popconfirm, Radio, Select } from 'antd';
import { Images } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const MenuItemGroup = Menu.ItemGroup;

export const PHOTOS_SORT_BY_CAPTURE_TIME = 'clickedAt';
export const PHOTOS_SORT_BY_NAME_SEQUENCE = 'sequence';
export const PHOTOS_SORT_BY_NAME = 'name';

const SORT_OPTIONS = [
	{
		label: 'Capture Time',
		value: PHOTOS_SORT_BY_CAPTURE_TIME,
		description: 'Sort by when the photo was taken (from camera data)'
	},
	{
		label: 'Name',
		value: PHOTOS_SORT_BY_NAME,
		description: 'Sort alphabetically by filename'
	},
	{
		label: 'Name Sequence',
		value: PHOTOS_SORT_BY_NAME_SEQUENCE,
		description: 'Sort by filename including sequence numbers'
	}
];

class SortByComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576,
			openPopconfirm: null
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) {
			this.setState({ isMobile: true });
		} else if (window.innerWidth > 576 && this.state.isMobile) {
			this.setState({ isMobile: false });
		}
	};

	render() {
		const { sortBy, updateSortBy } = this.props;
		const { openPopconfirm } = this.state;

		const styles = {
			menuWrapper: {
				borderRadius: '8px',
				boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
				marginTop: '10px',
				paddingBottom: '6px'
			},
			titleWrapper: {
				display: 'flex',
				alignItems: 'center',
				padding: '10px 12px 8px',
				borderBottom: '1px solid #f0f0f0',
				marginLeft: '4px'
			},
			titleIcon: {
				marginRight: '10px',
				display: 'flex',
				alignItems: 'center'
			},
			titleText: {
				margin: 0,
				color: '#6F4898',
				fontSize: '13px',
				fontWeight: 600,
				letterSpacing: '0.5px'
			},
			menuItem: {
				padding: '4px 16px',
				height: '28px',
				lineHeight: '28px',
				margin: 0
			},
			radioLabel: {
				fontSize: '14px',
				color: '#4B5563'
			},
			mobileSelect: {
				fontSize: '14px',
				width: '100%'
			},
			menuGroup: {
				paddingBottom: 0
			},
			clickableArea: {
				width: '100%',
				height: '100%',
				cursor: 'pointer',
				display: 'flex',
				alignItems: 'center'
			}
		};

		if (this.state.isMobile) {
			return (
				<div>
					<Select
						size="middle"
						onChange={updateSortBy}
						style={styles.mobileSelect}
						placeholder="Sort By"
						value={sortBy || PHOTOS_SORT_BY_CAPTURE_TIME}
						options={SORT_OPTIONS}
					/>
				</div>
			);
		}

		return (
			<Menu selectedKeys={[]} style={styles.menuWrapper}>
				<MenuItemGroup
					style={styles.menuGroup}
					title={
						<div style={styles.titleWrapper}>
							<span style={styles.titleIcon}>
								<Images color="#6F4898" size={18} />
							</span>
							<h4 style={styles.titleText}>SORT PHOTOS BY</h4>
						</div>
					}>
					{SORT_OPTIONS.map((option, index) => {
						const isSelected =
							sortBy === option.value || (!sortBy && option.value === PHOTOS_SORT_BY_CAPTURE_TIME);

						return (
							<Menu.Item
								key={index + 1}
								style={{
									...styles.menuItem,
									...(index === SORT_OPTIONS.length - 1 && { marginBottom: '2px' })
								}}>
								<Popconfirm
									title={option.description}
									onConfirm={() => {
										updateSortBy(option.value);
										this.setState({ openPopconfirm: null });
									}}
									onCancel={() => this.setState({ openPopconfirm: null })}
									okText="Confirm"
									cancelText="Cancel"
									open={openPopconfirm === index}
									onOpenChange={(visible) => {
										if (visible) {
											this.setState({ openPopconfirm: index });
										} else {
											this.setState({ openPopconfirm: null });
										}
									}}>
									<div
										style={styles.clickableArea}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											this.setState({ openPopconfirm: index });
										}}>
										<Radio checked={isSelected}>
											<span style={styles.radioLabel}>{option.label}</span>
										</Radio>
									</div>
								</Popconfirm>
							</Menu.Item>
						);
					})}
				</MenuItemGroup>
			</Menu>
		);
	}
}

SortByComp.propTypes = {
	sortBy: PropTypes.string,
	updateSortBy: PropTypes.func.isRequired
};

export default SortByComp;
