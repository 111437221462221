import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Menu, message, Space, Tooltip, Typography } from 'antd';
import { HelpCircle, Youtube } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import '../styles/profile.sass';
import { VIDEO_LINK_NAME_LENGTH } from './AddVideoLinkComp';
import PreviewModal from './PreviewThumbnailModal';
import ThumbnailModal from './ThumbnailModal';
import VideoListItem from './VideoListItem';

const { Title } = Typography;
const MenuItemGroup = Menu.ItemGroup;

const AddVideoLinkForm = ({ addVideoLink }) => {
	const [form] = Form.useForm();

	const isValidUrl = (string) => {
		try {
			new URL(string);
			return true;
		} catch (_) {
			return false;
		}
	};

	const validateVideoUrl = (_, value) => {
		if (!value) {
			return Promise.reject('Please enter video link');
		}
		if (!isValidUrl(value)) {
			return Promise.reject('Please enter a valid URL');
		}
		return Promise.resolve();
	};

	const handleSubmit = (values) => {
		addVideoLink(values.name, values.link);
		form.resetFields();
	};

	return (
		<Form
			form={form}
			onFinish={handleSubmit}
			style={{
				padding: '12px 10px 8px',
				background: '#f9fafb',
				borderRadius: '8px',
				margin: '0 8px 8px'
			}}>
			<Space direction="vertical" size={8} style={{ width: '100%' }}>
				<Form.Item
					name="name"
					rules={[{ required: true, message: 'Please enter video name' }]}
					style={{ marginBottom: 0 }}>
					<Input
						placeholder="Video Name"
						maxLength={VIDEO_LINK_NAME_LENGTH}
						style={{ borderColor: '#6F4898', height: '36px' }}
					/>
				</Form.Item>
				<Form.Item
					name="link"
					rules={[{ validator: validateVideoUrl }]}
					style={{ marginBottom: 0 }}>
					<Input placeholder="Video Link" style={{ borderColor: '#6F4898', height: '36px' }} />
				</Form.Item>
				<Form.Item style={{ marginBottom: 0 }}>
					<Button
						type="primary"
						htmlType="submit"
						icon={<PlusOutlined />}
						style={{
							width: '100%',
							backgroundColor: '#6F4898',
							borderColor: '#6F4898',
							height: '36px'
						}}>
						Add Video
					</Button>
				</Form.Item>
			</Space>
		</Form>
	);
};

const VideoListComp = ({
	visibleEvent,
	className,
	allReduxState,
	videoLinks,
	onVideoClick,
	createVideoLink,
	videoLinkNameEdited,
	deleteVideoLink,
	videoLinkThumbnailEdited,
	uploadThumbnailImage,
	deletingVideoLinks
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [openPreviewModal, setOpenPreviewModal] = useState(false);
	const [clickedLink, setClickedLink] = useState({});
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isMobile, setIsMobile] = useState(window.screen.width <= 576);
	const uploadProgressRef = useRef(uploadProgress);
	const ThumbnailPhotInputRef = useRef(null);

	const ThumbnailUploadClick = () => {
		ThumbnailPhotInputRef.current.click();
	};

	const handleThumbnailChange = (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('file', file);
		if (file && file.type.startsWith('image/')) {
			const img = new Image();
			img.onload = function () {
				const width = img.naturalWidth;
				const height = img.naturalHeight;
				if (width > height) {
					uploadThumbnailImage(file, clickedLink, openNotificationWithIcon);
					if (uploadProgress < 90) {
						const interval = setInterval(() => {
							if (uploadProgressRef.current >= 90) {
								clearInterval(interval);
							} else {
								uploadProgressRef.current += 5;
								setUploadProgress(uploadProgressRef.current);
							}
						}, 300);
					}
				} else {
					openNotificationWithIcon('error', 'Please upload a Horizontal (Landscape) image');
				}
			};
			img.src = URL.createObjectURL(file);
		} else {
			openNotificationWithIcon('error', 'Invalid file format. Please select an image file.');
		}
	};

	const openNotificationWithIcon = (type, text, funcName) => {
		if (type === 'success') {
			message.success(text);
			if (funcName === 'upload') {
				uploadProgressRef.current = 100;
				setUploadProgress(uploadProgressRef.current);
				setTimeout(() => {
					uploadProgressRef.current = 0;
					setUploadProgress(uploadProgressRef.current);
				}, 500);
			}
		} else if (type === 'error') {
			message.error(text);
			if (funcName === 'upload') {
				uploadProgressRef.current = 99;
				setUploadProgress(uploadProgressRef.current);
				setTimeout(() => {
					uploadProgressRef.current = 0;
					setUploadProgress(uploadProgressRef.current);
				}, 500);
			}
		}
	};

	const handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !isMobile) setIsMobile(true);
		else if (window.innerWidth > 576 && isMobile) setIsMobile(false);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	const styles = {
		cardContainer: {
			background: '#fff',
			borderRadius: '8px',
			boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
			marginTop: '10px',
			marginBottom: '10px',
			overflow: 'hidden'
		},
		headerWrapper: {
			display: 'flex',
			alignItems: 'center',
			padding: '10px 12px 8px',
			borderBottom: '1px solid #f0f0f0',
			justifyContent: 'center'
		},
		headerContent: {
			display: 'flex',
			alignItems: 'center',
			gap: '8px'
		},
		headerTitle: {
			margin: 0,
			color: '#6F4898',
			fontSize: '13px',
			fontWeight: 600,
			letterSpacing: '0.5px'
		},
		menuContent: {
			padding: '4px 0'
		},
		menuItem: {
			padding: '4px 8px',
			margin: isMobile ? '8px 0px' : '2px 0',
			height: '32px',
			lineHeight: '32px'
		},
		videoList: {
			marginTop: '4px',
			padding: '0 8px'
		}
	};

	return (
		<>
			<ThumbnailModal
				open={openModal}
				setOpen={setOpenModal}
				ThumbnailUploadClick={ThumbnailUploadClick}
				handleThumbnailChange={handleThumbnailChange}
				ThumbnailPhotInputRef={ThumbnailPhotInputRef}
				clickedLink={clickedLink}
				setClickedLink={setClickedLink}
				videoLinkThumbnailEdited={videoLinkThumbnailEdited}
				visibleEvent={visibleEvent}
				uploadThumbnailImage={uploadThumbnailImage}
				openNotificationWithIcon={openNotificationWithIcon}
				setOpenPreviewModal={setOpenPreviewModal}
				uploadProgress={uploadProgress}
			/>
			<PreviewModal
				open={openPreviewModal}
				setOpen={setOpenPreviewModal}
				clickedLink={clickedLink}
			/>
			<div style={styles.cardContainer}>
				<Menu style={{ border: 'none' }} selectedKeys={[]}>
					<MenuItemGroup
						title={
							!isMobile && (
								<div style={styles.headerWrapper}>
									<div style={styles.headerContent}>
										<Youtube color="#6F4898" size={18} />
										<h4 style={styles.headerTitle}>VIDEOS</h4>
										<Tooltip
											title={
												<div>
													<p>For the best experience:</p>
													<ul style={{ paddingLeft: '20px', listStyle: 'disc' }}>
														<li>Upload your video to YouTube</li>
														<li>Copy and paste the video link here</li>
														<li>For privacy, you can set your YouTube video as "Unlisted"</li>
														<li>We optimize YouTube videos for seamless playback</li>
														<li>Any valid video URL is supported (not just YouTube)</li>
													</ul>
												</div>
											}
											placement="right">
											<HelpCircle
												size={16}
												color="#6F4898"
												style={{ cursor: 'pointer', marginLeft: '4px' }}
											/>
										</Tooltip>
									</div>
								</div>
							)
						}>
						<div style={styles.menuContent}>
							<AddVideoLinkForm
								addVideoLink={(name, link) => {
									createVideoLink(name, link);
								}}
							/>
							<div style={styles.videoList}>
								{videoLinks?.map((videoLink, i) => (
									<VideoListItem
										key={videoLink.id}
										name={videoLink.name}
										id={videoLink.id}
										itemNameMaxLength={VIDEO_LINK_NAME_LENGTH}
										onItemClick={() => onVideoClick(videoLink.link)}
										editItem={(newName) => {
											videoLinkNameEdited(i, visibleEvent, newName, videoLink);
										}}
										deleteItem={() => deleteVideoLink(i, visibleEvent, videoLink)}
										onThumbnailClicked={() => {
											setOpenModal(true);
											setClickedLink({ ...videoLink, index: i, eventId: visibleEvent.id });
										}}
									/>
								))}
							</div>
						</div>
					</MenuItemGroup>
				</Menu>
			</div>
		</>
	);
};

VideoListComp.propTypes = {
	videoLinks: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		}).isRequired
	),
	allReduxState: PropTypes.object.isRequired,
	visibleEvent: PropTypes.object.isRequired,
	onVideoClick: PropTypes.func.isRequired,
	createVideoLink: PropTypes.func.isRequired,
	videoLinkNameEdited: PropTypes.func.isRequired,
	videoLinkThumbnailEdited: PropTypes.func.isRequired,
	deleteVideoLink: PropTypes.func.isRequired
};

export default VideoListComp;
