import {
	EllipsisOutlined,
	LeftCircleTwoTone,
	RightCircleTwoTone,
	WarningOutlined
} from '@ant-design/icons';
import { Button, Carousel, Dropdown, Menu, Popconfirm } from 'antd';
import { goBack, replace } from 'connected-react-router';
import { ArrowLeft } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	deletePhoto,
	setCoverPhoto,
	setEventCoverPhotoMAction,
	updateFullScreenPhotoIndex
} from '../actions/pouchDBActions';
import PhotoComp from '../components/PhotoComp';
import { TruncatedText } from '../components/TruncatedTextComponent';

class FullScreenComp extends Component {
	constructor(props) {
		super(props);
		this.currentIndex = this.props.index;
		this.carousel = React.createRef();
		this.state = {
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
		const { albumName, eventChannel, goToEvents, userId } = this.props;
		if (!albumName || !eventChannel || !userId) {
			goToEvents();
			return null;
		}

		const track = this.carousel.current.innerSlider.list.querySelector('.slick-track');
		setTimeout(() => {
			const slide = track.querySelector('.slick-slide');
			if (slide) {
				console.log('Slider focused to make it changeable by keyboard.');
				slide.focus();
			}
		}, 10);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	render() {
		const {
			userId,
			event,
			album,
			photos,
			index,
			onPhotoIndexChanged,
			goBackClicked,
			albumName,
			eventChannel,
			deletePhoto,
			albumIndex,
			setCoverPhoto,
			setEventCoverPhoto,
			pageNumber,
			currentPath
		} = this.props;

		if (!photos[this.currentIndex]) {
			goBackClicked();
			return null;
		}

		if (!albumName || !eventChannel) {
			return null;
		}

		const deletePopOver = (
			<div>
				<p>Are you sure?</p>
				<p>This will permanently delete this photo.</p>
			</div>
		);

		let isThisEventCover =
			event.coverPhotoS3Key &&
			event.coverPhotoS3Key.indexOf(photos[this.currentIndex].s3FileName) !== -1;

		let isThisAlbumCover =
			album.coverPhotoS3Key &&
			album.coverPhotoS3Key.indexOf(photos[this.currentIndex].s3FileName) !== -1;

		return (
			<div>
				{this.state.isMobile ? (
					<div className="pageTitleBar" style={{ width: '100%', padding: '20px 16px' }}>
						<div style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => goBackClicked()}>
							<Button
								onClick={(e) => goBackClicked()}
								icon={<ArrowLeft />}
								style={{ marginRight: 10, padding: 10 }}
							/>{' '}
							<h3 className="pageTitle" style={{ margin: 0 }}>
								{albumName}
							</h3>
						</div>
						<div style={{ display: 'flex' }}>
							<Dropdown
								overlay={
									<Menu>
										<Menu.Item key="0">
											{isThisEventCover ? (
												<span>This is your Event Cover!</span>
											) : (
												<span
													onClick={() => {
														setEventCoverPhoto(event, photos[this.currentIndex]);
													}}>
													Set as Event Cover
												</span>
											)}
										</Menu.Item>
										<Menu.Item key="1">
											{!album?.isGuestUploads && (
												<>
													{isThisAlbumCover ? (
														<span>This is your Album Cover!</span>
													) : (
														<span
															onClick={() => {
																setCoverPhoto(
																	albumIndex,
																	event,
																	album,
																	photos[this.currentIndex],
																	pageNumber
																);
															}}>
															Set as Album Cover
														</span>
													)}
												</>
											)}
										</Menu.Item>
										<Menu.Divider />
										<Menu.Item key="3">
											<Popconfirm
												title={deletePopOver}
												icon={<WarningOutlined />}
												onConfirm={(e) => {
													e.stopPropagation();
													deletePhoto(userId, event, album, photos[this.currentIndex], pageNumber);
												}}
												onCancel={(e) => {
													e.stopPropagation();
												}}
												okText="Delete Anyway!"
												cancelText="Cancel">
												<Button type="primary" size="small">
													Delete Photo
												</Button>
											</Popconfirm>
										</Menu.Item>
									</Menu>
								}
								trigger={['click']}>
								<EllipsisOutlined rotate={90} style={{ fontSize: '28px' }} />
							</Dropdown>
						</div>
					</div>
				) : (
					<div className="pageTitleBar" style={{ marginLeft: '20px', marginRight: '20px' }}>
						<Button
							onClick={(e) => goBackClicked()}
							icon={<ArrowLeft />}
							style={{ marginRight: 20, padding: 10 }}
						/>{' '}
						<h1 style={{ margin: 0 }} className="pageTitle">
							<TruncatedText text={albumName} />
						</h1>
						{isThisEventCover ? (
							<p style={{ margin: '10px', marginTop: '18px', minWidth: '150px' }}>
								This is your Event Cover!
							</p>
						) : (
							<Button
								size="small"
								style={{ marginRight: '10px' }}
								onClick={() => {
									setEventCoverPhoto(event, photos[this.currentIndex]);
								}}>
								Set as Event Cover
							</Button>
						)}
						{!album?.isGuestUploads && (
							<>
								{isThisAlbumCover ? (
									<p style={{ margin: '10px', marginTop: '18px', minWidth: '150px' }}>
										This is your Album Cover!
									</p>
								) : (
									<Button
										size="small"
										style={{ marginRight: '10px' }}
										onClick={() => {
											setCoverPhoto(
												albumIndex,
												event,
												album,
												photos[this.currentIndex],
												pageNumber
											);
										}}>
										Set as Album Cover
									</Button>
								)}
							</>
						)}
						<Popconfirm
							title={deletePopOver}
							icon={<WarningOutlined />}
							onConfirm={(e) => {
								e.stopPropagation();
								deletePhoto(userId, event, album, photos[this.currentIndex], pageNumber);
							}}
							onCancel={(e) => {
								e.stopPropagation();
							}}
							okText="Delete Anyway!"
							cancelText="Cancel">
							<Button type="primary" size="small">
								Delete Photo
							</Button>
						</Popconfirm>
					</div>
				)}

				<Carousel
					ref={this.carousel}
					className="fullscreen-carousel"
					effect="fade"
					lazyLoad="ondemand"
					initialSlide={index}
					dots={false}
					afterChange={(index) => {
						console.log('onPhotoIndexChanged');
						this.currentIndex = index;
						onPhotoIndexChanged(index, currentPath);
					}}>
					{photos.map((photo) => (
						<PhotoComp key={photo.id} isThumbnail={false} photo={photo} />
					))}
				</Carousel>

				{this.state.isMobile ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							paddingBottom: '30px'
						}}>
						<LeftCircleTwoTone
							style={{ fontSize: '2rem', margin: '0px 10px' }}
							onClick={() => this.carousel.current.prev()}
							twoToneColor="#6F4898"
						/>
						<RightCircleTwoTone
							style={{ fontSize: '2rem', margin: '0px 10px' }}
							onClick={() => this.carousel.current.next()}
							twoToneColor="#6F4898"
						/>
					</div>
				) : (
					<>
						<LeftCircleTwoTone
							className="carousel-arrow carousel-left-arrow"
							onClick={() => this.carousel.current.prev()}
							twoToneColor="#6F4898"
						/>
						<RightCircleTwoTone
							className="carousel-arrow carousel-right-arrow"
							onClick={() => this.carousel.current.next()}
							twoToneColor="#6F4898"
						/>
					</>
				)}
				{/* Photo ID display */}
				<div
					style={{
						position: 'fixed',
						bottom: '4px',
						width: '100%',
						textAlign: 'center',
						color: '#999',
						fontSize: '10px'
					}}>
					{album?.id} • {photos[this.currentIndex]?.id}
				</div>
			</div>
		);
	}
}

FullScreenComp.propTypes = {
	photos: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			s3FileName: PropTypes.string.isRequired
		}).isRequired
	).isRequired,
	userId: PropTypes.string,
	index: PropTypes.number.isRequired,
	pageNumber: PropTypes.number,
	onPhotoIndexChanged: PropTypes.func.isRequired,
	goBackClicked: PropTypes.func.isRequired,
	goToEvents: PropTypes.func.isRequired,
	eventChannel: PropTypes.string,
	albumName: PropTypes.string,
	deletePhoto: PropTypes.func.isRequired,
	setCoverPhoto: PropTypes.func.isRequired,
	setEventCoverPhoto: PropTypes.func.isRequired,
	event: PropTypes.object,
	album: PropTypes.object,
	albumIndex: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	userId: state.auth.userId,
	photos: state.pouchDB.visiblePhotos,
	index: parseInt(ownProps.match.params.index, 10),
	albumIndex: parseInt(ownProps.match.params.albumIndex, 10),
	eventChannel: state.pouchDB.visibleEvent ? state.pouchDB.visibleEvent.channel : undefined,
	albumName: state.pouchDB.clickedAlbum ? state.pouchDB.clickedAlbum.name : undefined,
	event: state.pouchDB.visibleEvent,
	album: state.pouchDB.clickedAlbum,
	pageNumber: state.pouchDB.currentPage,
	currentPath: ownProps.location.pathname
});

const mapDispatchToProps = (dispatch) => ({
	goBackClicked: () => {
		dispatch(goBack());
	},
	goToEvents: () => {
		dispatch(replace('/events'));
	},
	onPhotoIndexChanged: (index, currentPath) => {
		dispatch(updateFullScreenPhotoIndex(index, currentPath, true));
	},
	deletePhoto: (userId, event, album, photo, pageNumber) => {
		dispatch(deletePhoto(userId, event, album, photo, pageNumber));
	},
	setCoverPhoto: (albumIndex, event, album, photo, pageNumber) => {
		dispatch(setCoverPhoto(albumIndex, event, album, photo, pageNumber));
	},
	setEventCoverPhoto: (event, photo) => {
		dispatch(setEventCoverPhotoMAction(event, photo));
	}
});

const FullScreen = connect(mapStateToProps, mapDispatchToProps)(FullScreenComp);

export default FullScreen;
