import { generate1080wUrl } from '../helpers/imageUrlGenerator';
import VideoLink from '../pouchDB/models/VideoLink';
import {
	ACTION_VIDEOLINK_CREATED,
	ACTION_VIDEOLINK_DELETED,
	ACTION_VIDEOLINK_UPDATED,
	ACTION_VISIBLE_VIDEOLINKS_UPDATED
} from './action_types';

export const visibleVideoLinkUpdatedAction = (videoLinks) => ({
	type: ACTION_VISIBLE_VIDEOLINKS_UPDATED,
	videoLinks: videoLinks
});

export const videoLinkCreated = (videoLink) => ({
	type: ACTION_VIDEOLINK_CREATED,
	videoLink: videoLink
});

export const videoLinkUpdated = (videoLinkIndex, videoLink) => ({
	type: ACTION_VIDEOLINK_UPDATED,
	videoLinkIndex: videoLinkIndex,
	videoLink: videoLink
});

export const videoLinkDeleted = (videoLinkIndex) => ({
	type: ACTION_VIDEOLINK_DELETED,
	videoLinkIndex: videoLinkIndex
});

export const fetchVideoLinksMAction = (eventDocId) => {
	return (dispatch) => {
		VideoLink.fetchVideoLinks(eventDocId)
			.then((videoLinks) => {
				console.log(videoLinks);
				dispatch(visibleVideoLinkUpdatedAction(videoLinks));
			})
			.catch((error) => {
				console.log(error);
				console.log('API_ERROPR');
			});
	};
};

export const createVideoLinkMAction = (userId, event, name, link) => {
	return (dispatch, getState) => {
		VideoLink.createVideoLink(userId, getState().auth.token, event.id, name, link)
			.then((videoLink) => {
				dispatch(videoLinkCreated(videoLink));
			})
			.catch((error) => {
				console.log(error);
				console.log('API_ERROPR');
			});
	};
};

export const videoLinkClickedMAction = (link) => {
	return (dispatch) => {
		window.open(link, '_blank');
	};
};

export const editVideoLinkNameMAction = (videoLinkIndex, event, videoLink, newVideoLinkName) => {
	return (dispatch, getState) => {
		VideoLink.updateVideoLinkName(getState().auth.userId, videoLink.id, newVideoLinkName)
			.then(() => {
				videoLink.name = newVideoLinkName;
				dispatch(videoLinkUpdated(videoLinkIndex, videoLink));
			})
			.catch((error) => {
				console.log(error);
				console.log('API_ERROPR');
			});
	};
};

export const editVideoLinkThumbnailMAction = (
	videoLinkIndex,
	event,
	videoLink,
	name,
	newThumbnailUrl,
	openNotificationWithIcon
) => {
	return (dispatch, getState) => {
		VideoLink.updateVideoLinkThumbnail(getState().auth.userId, videoLink.id, name, newThumbnailUrl)
			.then(() => {
				videoLink.thumbnailURL = newThumbnailUrl;
				dispatch(videoLinkUpdated(videoLinkIndex, videoLink));
				openNotificationWithIcon('success', 'Video thumbnail saved sucessfully');
			})
			.catch((error) => {
				console.log(error);
				console.log('API_ERROPR');
				openNotificationWithIcon('error', 'Something went wrong while saving thumbnail image');
			});
	};
};

export const uploadThumbnailImage = (image, videoLink, openNotificationWithIcon) => {
	return (dispatch, getState) => {
		let thumbnailUrl, s3Key; // Declare variables in outer scope

		console.log('Uploading thumbnail image xxx:', image, videoLink);

		VideoLink.uploadThumbnailImage(getState().auth.userId, image, videoLink)
			.then((uploadResponse) => {
				// uploadResponse is now the obj from API containing filePath
				console.log('Upload Response:', uploadResponse);

				if (!uploadResponse.filePath) {
					throw new Error('Invalid upload response - missing filePath');
				}

				// Store values in outer scope variables
				thumbnailUrl = generate1080wUrl(uploadResponse.filePath);
				s3Key = uploadResponse.filePath;

				return VideoLink.updateVideoLinkThumbnail(
					getState().auth.userId,
					videoLink.id,
					videoLink.name,
					thumbnailUrl,
					s3Key
				);
			})
			.then(() => {
				// Update the videoLink object with stored values
				videoLink.thumbnailURL = thumbnailUrl;
				videoLink.thumbnailS3Key = s3Key;

				dispatch(videoLinkUpdated(videoLink.index, videoLink));
				openNotificationWithIcon('success', 'Video thumbnail uploaded successfully', 'upload');
			})
			.catch((error) => {
				console.error('Error uploading thumbnail:', error);
				console.error('Error details:', error.message);
				openNotificationWithIcon(
					'error',
					'Something went wrong while uploading thumbnail image',
					'upload'
				);
			});
	};
};

export const deleteVideoLinkMAction = (videoLinkIndex, event, videoLinkDocId) => {
	return (dispatch, getState) => {
		VideoLink.deleteVideoLink(getState().auth.userId, videoLinkDocId)
			.then(() => {
				dispatch(videoLinkDeleted(videoLinkIndex));
			})
			.catch((error) => {
				console.log(error);
				console.log('API_ERROPR');
			});
	};
};
