import fetch from 'cross-fetch';
import ls from 'local-storage';

import { generateFullImageUrl, generateThumbnailUrl } from '../../helpers/imageUrlGenerator';
import { swaggerClient } from '../../swagger';

const PHOTOS_PER_PAGE = 50;
const LOCAL_STORAGE_KEY_PHOTOS_PER_PAGE = 'photosPerPage';

export const PHOTOS_SORT_BY_CLICKED_AT = 'clickedAt';
export const PHOTOS_SORT_BY_NAME = 'name';

let photosPerPage = undefined;
let lastGetPhotoPromiseData = undefined;

class Photo {
	static getS3Key = (photo) => photo.event + '/' + photo.s3FileName;

	static generateS3Key = (channel, file) => {
		let s3Key = new Date().getTime() + '-' + file.name;
		return s3Key.replace(/[^a-zA-Z0-9._-]/g, '').toLowerCase();
	};

	static generatePhotoId = (channel, file) => {
		let size;
		let lastModified;

		if (file.size) {
			size = file.size;
		} else {
			size = 0;
		}

		if (file.lastModified) {
			lastModified = file.lastModified;
		} else {
			lastModified = 0;
		}
		let docId = lastModified + '-' + size + '-' + channel + '-' + file.name;
		return docId.replace(/[^a-zA-Z0-9._-]/g, '').toLowerCase();
	};

	static createPhotoToUpload = (docId, file, userId, event, albumId, clickedAt) => {
		if (!clickedAt) {
			if (file.lastModified) {
				clickedAt = new Date(file.lastModified).toISOString();
			}
		}

		let photoDoc = {
			userId: userId,
			eventDocId: event,
			albumDocId: albumId,
			uniqueId: docId,
			clickedAt: clickedAt,
			name: file.name
		};

		console.log(photoDoc);
		return photoDoc;
	};

	static filterDuplicates = (userId, eventDocId, photoDocIds) => {
		return swaggerClient()
			.apis.photo.filter_dup_photos({
				userId: userId,
				eventDocId: eventDocId,
				photoDocIds: photoDocIds
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static uploadPhotos = (photoToCreate, image) => {
		return swaggerClient().apis.photo.upload_photos({
			userId: photoToCreate.userId,
			eventDocId: photoToCreate.eventDocId,
			albumDocId: photoToCreate.albumDocId,
			uniqueId: photoToCreate.uniqueId,
			clickedAt: photoToCreate.clickedAt,
			name: photoToCreate.name,
			file: image
		});
	};

	static fetchPhotos = (event, albumDocId, pageNumber, consistent) => {
		return swaggerClient()
			.apis.photo.photo_list_by_album({
				eventDocId: event.id,
				albumDocId: albumDocId,
				consistent: consistent,
				sortBy: event.sortBy,
				limit: Photo.photosPerPage().toString(),
				skip: (pageNumber * Photo.photosPerPage()).toString()
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static deletePhoto(userId, photo) {
		return swaggerClient().apis.photo.delete_photo({
			userId: userId,
			photoDocId: photo.id
		});
	}

	static deletePhotos(userId, photoDocIds) {
		return swaggerClient().apis.photo.delete_photos({
			userId: userId,
			photoDocIds: photoDocIds
		});
	}

	static movePhotos(userId, albumDocId, photoDocIds) {
		return swaggerClient().apis.photo.move_photos({
			userId: userId,
			toAlbumDocId: albumDocId,
			photoDocIds: photoDocIds
		});
	}

	static photosPerPage = () => {
		if (photosPerPage) {
			return photosPerPage;
		}
		let storedPhotosPerPage = ls.get(LOCAL_STORAGE_KEY_PHOTOS_PER_PAGE);
		if (storedPhotosPerPage) {
			return parseInt(storedPhotosPerPage, 10);
		}

		Photo.updatePhotosPerPage(PHOTOS_PER_PAGE);
		return PHOTOS_PER_PAGE;
	};

	static updatePhotosPerPage = (paramPhotosPerPage) => {
		ls.set(LOCAL_STORAGE_KEY_PHOTOS_PER_PAGE, paramPhotosPerPage);
		photosPerPage = parseInt(paramPhotosPerPage, 10);
	};

	static getPhoto = (s3Key, isThumbnail) => {
		let url = isThumbnail ? generateThumbnailUrl(s3Key) : generateFullImageUrl(s3Key);

		return fetch(url, {
			method: 'GET',
			headers: {
				accept:
					'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;'
			}
		})
			.then((response) => {
				if (response.status === 200) return response.blob();
				throw new Error(`HTTP error! status: ${response.status}`);
			})
			.then((blob) => URL.createObjectURL(blob))
			.catch((error) => {
				console.error('Photo.getPhoto: Error fetching photo', { s3Key, error });
				throw error;
			});
	};
}

export default Photo;
