//new code

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EVENT_NAME_LENGTH } from './CreateEventComp';
import PhotoComp from './PhotoComp';
import TextFieldComp from './TextFieldComp';

import RenewEventModal from './RenewEventModal';
import RenewalLinkModal from './RenewalLinkModal';

import { Card, Tag } from 'antd';

import { ReactComponent as IndicatorSVG } from '../svg/Indicator.svg';
import { ReactComponent as UploadSVG } from '../svg/Upload.svg';
import DeleteEventModal from './DeleteEventModal';
import DownloadAllPhotosModal from './DownloadAllPhotosModal';

import { Image } from 'lucide-react';
import { generate1080wUrl } from '../helpers/imageUrlGenerator';
import EditEventIdModal from './EditEventIdModal';
import EditEventNameModal from './EditEventNameModal';

class EventComp extends Component {
	eventHeaderNoPhotos() {}

	getEventHeader(state, photo) {
		if (photo) {
			return <PhotoComp className="event-card-header-photo" photo={photo} eventThumbnail />;
		}

		if (state === 'created') {
			return (
				<div className="event-card-header-nophoto">
					<div className="event-card-header-nophoto-child">
						<UploadSVG />
					</div>
					<div className="event-card-header-nophoto-child event-card-header-text">
						Upload Photos
					</div>
				</div>
			);
		}

		if (state === 'rejected') {
			return (
				<div className="event-card-header-nophoto">
					<div className="event-card-header-nophoto-child">
						<h5 className="event-card-error">Event rejected.</h5>
						<h5 className="event-card-error">
							Please{' '}
							<div
								onClick={(e) => {
									e.preventDefault();
									window.open('https://kamero.in/#contact', '_blank');
								}}>
								contact us
							</div>{' '}
							to create manually.
						</h5>
					</div>
				</div>
			);
		}

		return (
			<div className="event-card-header-nophoto">
				<div className="event-card-header-nophoto-child">
					<IndicatorSVG className="create-event-indicator" />
				</div>
				<div className="event-card-header-nophoto-child event-card-header-text">
					Creating event...
				</div>
			</div>
		);
	}

	getEditPopOver(name) {
		return (
			<div>
				<p>Edit Event Name</p>
				<TextFieldComp
					shouldAutoFocus
					maxLength={EVENT_NAME_LENGTH}
					placeholder="New Event Name"
					initialValue={name}
					textChanged={(eventName) => {
						this.newEventName = eventName;
					}}
				/>
			</div>
		);
	}

	getEditHashIdPopOver(eventId) {
		return (
			<div>
				<p>Set Event Id</p>
				<TextFieldComp
					shouldAutoFocus
					maxLength={EVENT_NAME_LENGTH}
					placeholder="New Event ID"
					initialValue={eventId}
					textChanged={(eventId) => {
						this.newEventID = eventId;
					}}
				/>
			</div>
		);
	}

	handleRenewed = () => {
		this.props.refreshEvents();
	};

	handleEventDeleted = () => {
		this.props.refreshEvents();
	};

	render() {
		const {
			eventId,
			name,
			maxPhotos,
			guestMaxPhotos,
			expiresAt,
			state,
			coverPhotoS3Key,
			eventType
		} = this.props.event;

		const {
			onClick,
			photoCount,
			userId,
			deleteEvent,
			eventNameEdited,
			setEventId,
			isExpired,
			subscription
		} = this.props;

		console.log(eventId, expiresAt, 'is expired', isExpired);

		const isSubscriptionEvent = eventType === 1;
		const eventExpiryDate = isSubscriptionEvent ? subscription?.expiresAt : expiresAt;
		const expiresAtDate = new Date(eventExpiryDate);

		const expiresAtShort =
			expiresAtDate.getDate() +
			'/' +
			(expiresAtDate.getMonth() + 1) +
			'/' +
			expiresAtDate.getFullYear().toString().substr(2, 2);

		let photoDetail;
		if (isSubscriptionEvent) {
			photoDetail = null;
		} else if (photoCount !== undefined) {
			photoDetail = photoCount + '/' + (maxPhotos - (guestMaxPhotos || 0));
		} else {
			photoDetail = '-- /' + maxPhotos;
		}

		const defaultCoverImageUrl =
			'https://media.kamero.ai/eyJrZXkiOiJsb2dvcy9uby1ldmVudC1jb3Zlci5qcGVnIiwiYnVja2V0Ijoia2FtZXJvLXB1YmxpYyJ9';

		let coverImageUrl = defaultCoverImageUrl;
		if (coverPhotoS3Key) {
			coverImageUrl = generate1080wUrl(coverPhotoS3Key);
		}

		return (
			<div className={`event-card-wrapper ${isExpired ? 'expired' : ''}`}>
				<Card
					cover={
						<div style={{ position: 'relative' }}>
							<img
								alt="Event Cover Photo"
								src={coverImageUrl}
								style={{
									minHeight: '260px',
									maxHeight: '260px',
									width: '100%',
									objectFit: 'cover'
								}}
							/>
							{!isExpired && <ExpiryOverlay expiryDate={expiresAtShort} />}
							<PhotoCountOverlay
								photoCount={photoCount}
								maxPhotos={maxPhotos}
								guestMaxPhotos={guestMaxPhotos}
								isSubscriptionEvent={eventType === 1}
							/>
							{isSubscriptionEvent && <SubscriptionTag />}
						</div>
					}
					style={{
						borderRadius: '12px',
						overflow: 'hidden'
					}}
					actions={[
						<div className="event-id-container">
							<span className="event-id">ID : {eventId}</span>
							<div className="event-id-edit" onClick={(e) => e.stopPropagation()}>
								<EditEventIdModal event={this.props.event} userId={userId} onEdit={setEventId} />
							</div>
						</div>
					]}
					hoverable
					className={`event-card ${isExpired ? 'expired' : ''}`}
					onClick={(e) => {
						if (!isExpired) {
							onClick();
						}
					}}>
					{state === 'created' || state === 'rejected' ? (
						<div
							className="event-card-detail"
							style={{ marginTop: '-20px', marginBottom: '-22px' }}>
							<div className="event-name-container">
								<h3 className="event-name">{name}</h3>
								<div className="event-actions" onClick={(e) => e.stopPropagation()}>
									<EditEventNameModal
										event={this.props.event}
										userId={userId}
										onEdit={eventNameEdited}
									/>
									<DeleteEventModal
										event={this.props.event}
										userId={userId}
										deleteEvent={deleteEvent}
										onDeleted={this.handleEventDeleted}
										type="icon"
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="event-card-detail">
							<h3 className="event-card-detail-title-child">{'Creating ' + name + ' ...'}</h3>
						</div>
					)}
				</Card>
				{isExpired && (
					<div className="expired-overlay">
						<ExpiredOverlay expiryDate={expiresAtShort} />
						<RenewEventModal event={this.props.event} onRenewed={this.handleRenewed} />
						<DeleteEventModal
							event={this.props.event}
							userId={userId}
							deleteEvent={deleteEvent}
							onDeleted={this.handleEventDeleted}
						/>
						<RenewalLinkModal event={this.props.event} />
						<DownloadAllPhotosModal event={this.props.event} />
					</div>
				)}
			</div>
		);
	}
}

EventComp.propTypes = {
	onClick: PropTypes.func.isRequired,
	event: PropTypes.object.isRequired,
	photoCount: PropTypes.number,
	latestPhoto: PropTypes.object,
	eventNameEdited: PropTypes.func.isRequired,
	setEventId: PropTypes.func.isRequired,
	deleteEvent: PropTypes.func.isRequired,
	editEventExpiry: PropTypes.func.isRequired,
	userId: PropTypes.string,
	refreshEvents: PropTypes.func.isRequired,
	isExpired: PropTypes.bool.isRequired,
	subscription: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({
	userId: state.auth.userId,
	photoCount: state.pouchDB.photoCounts[ownProps.event.id]
		? state.pouchDB.photoCounts[ownProps.event.id].count
		: undefined,
	latestPhoto: state.pouchDB.photoCounts[ownProps.event.id]
		? state.pouchDB.photoCounts[ownProps.event.id].photo
		: undefined,
	subscription: state.auth.subscription
});

export default connect(mapStateToProps)(EventComp);

const ExpiryOverlay = ({ expiryDate }) => (
	<div className="expiry-overlay">
		<span>Valid upto: {expiryDate}</span>
	</div>
);

const ExpiredOverlay = ({ expiryDate }) => (
	<div className="expiry-overlay expired">
		<span>Expired on: {expiryDate}</span>
	</div>
);

const PhotoCountOverlay = ({ photoCount, maxPhotos, guestMaxPhotos, isSubscriptionEvent }) => {
	if (isSubscriptionEvent) {
		// For subscription events, show only the current count
		return (
			<div className="photo-count-overlay">
				<Image size={16} />
				<span>{photoCount || 0}</span>
			</div>
		);
	}

	// For pack-based events, show current/total format
	let displayCount;
	if (photoCount !== undefined) {
		displayCount = `${photoCount}/${maxPhotos - (guestMaxPhotos || 0)}`;
	} else {
		displayCount = `--/${maxPhotos}`;
	}

	return (
		<div className="photo-count-overlay">
			<Image size={16} />
			<span>{displayCount}</span>
		</div>
	);
};

const SubscriptionTag = () => (
	<Tag
		color="blue"
		style={{
			position: 'absolute',
			bottom: '12px',
			left: '50%',
			transform: 'translateX(-50%)'
		}}>
		Subscription
	</Tag>
);
