// src/components/EventsCodeCard.js
import { Button, Card, message, Modal, Tooltip } from 'antd';
import { Copy, Link, QrCode } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { commonCardStyles } from '../styles/cardStyles';
import FollowQRGenerator from './FollowQRGenerator';

const EventsCodeCard = ({ code, whitelabelId }) => {
	const [showQRModal, setShowQRModal] = useState(false);

	const getDisplayCode = () => {
		if (!code) return '------';
		return `FA-${code}`;
	};

	const getDeepLink = () => {
		if (!code) return '';
		const displayCode = getDisplayCode();
		if (whitelabelId === 'whitelabel-0') {
			return `https://app.kamero.ai/app?code=${displayCode}`;
		}
		const numericId = whitelabelId.split('-')[1];
		return `https://app.kamero.ai/${numericId}?code=${displayCode}`;
	};

	const copyCode = () => {
		if (!code) return;
		const codeWithPrefix = `FA-${code}`;
		navigator.clipboard
			.writeText(codeWithPrefix)
			.then(() => message.success('Code copied to clipboard!'))
			.catch(() => message.error('Failed to copy code'));
	};

	const copyLink = async () => {
		const link = getDeepLink();
		try {
			await navigator.clipboard.writeText(link);
			message.success('Link copied to clipboard');
		} catch (err) {
			message.error('Failed to copy link');
		}
	};

	const iconButtonStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '32px',
		width: '32px',
		padding: '0',
		minWidth: '32px'
	};

	return (
		<Card style={commonCardStyles.cardStyle} bodyStyle={commonCardStyles.bodyStyle}>
			<div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '12px' }}>
				<h3 style={commonCardStyles.headerStyle}>Follow All Your Events on App</h3>

				<div
					style={{
						...commonCardStyles.highlightSection,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '12px'
					}}>
					<span
						style={{
							fontSize: '24px',
							fontWeight: 'bold',
							color: '#6F4898',
							fontFamily: 'monospace',
							letterSpacing: '1px'
						}}>
						{getDisplayCode()}
					</span>
					<Tooltip title="Copy code">
						<Button
							type="primary"
							icon={<Copy size={16} />}
							onClick={copyCode}
							disabled={!code}
							style={{
								...iconButtonStyle,
								...commonCardStyles.primaryButton
							}}
						/>
					</Tooltip>
				</div>

				<div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '8px' }}>
					{code && (
						<>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									gap: '8px'
								}}>
								<Button
									onClick={() => window.open(getDeepLink(), '_blank')}
									style={{
										...commonCardStyles.button,
										padding: '0 12px',
										display: 'flex',
										alignItems: 'center',
										gap: '6px'
									}}>
									<Link size={16} />
									Follow Link
								</Button>
								<Tooltip title="Copy link">
									<Button icon={<Copy size={16} />} onClick={copyLink} style={iconButtonStyle} />
								</Tooltip>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Button
									type="default"
									icon={<QrCode size={16} />}
									onClick={() => setShowQRModal(true)}
									style={{
										...commonCardStyles.button,
										display: 'inline-flex',
										alignItems: 'center',
										gap: '6px'
									}}>
									Show QR Code
								</Button>
							</div>
						</>
					)}
				</div>
			</div>

			<div style={commonCardStyles.footerSection}>
				Use this code to follow all your events on app
			</div>

			<Modal
				title={
					<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
						<QrCode size={20} style={{ color: '#6F4898' }} />
						<span style={{ color: '#6F4898', margin: 0 }}>Follow All Events QR Code</span>
					</div>
				}
				open={showQRModal}
				onCancel={() => setShowQRModal(false)}
				footer={null}
				width={400}>
				<FollowQRGenerator data={getDeepLink()} />
			</Modal>
		</Card>
	);
};

EventsCodeCard.propTypes = {
	code: PropTypes.string,
	whitelabelId: PropTypes.string.isRequired
};

export default EventsCodeCard;
