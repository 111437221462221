import { Input, message, Modal } from 'antd';
import { LayoutGrid, Pencil, Plus, Trash2, Users } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const ALBUM_NAME_LENGTH = 20;

const commonStyles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: '320px',
		gap: '16px'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '12px',
		padding: '0 8px',
		marginBottom: '2px',
		marginTop: '6px'
	},
	headerText: {
		fontSize: '16px',
		fontWeight: '600',
		color: '#6F4898',
		margin: 0,
		display: 'flex',
		alignItems: 'center',
		gap: '8px'
	},
	albumCount: {
		fontSize: '14px',
		color: '#666',
		fontWeight: '400'
	},
	addAlbumContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '12px',
		padding: '16px',
		border: '1px solid #E0E0E0',
		borderRadius: '12px',
		background: '#FFFFFF',
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
	},
	input: {
		width: '100%',
		height: '36px',
		padding: '0 12px',
		border: '1px solid #E0E0E0',
		borderRadius: '8px',
		fontSize: '14px',
		outline: 'none',
		transition: 'border-color 0.2s',
		'&:focus': {
			borderColor: '#6F4898'
		}
	},
	addButton: {
		height: '36px',
		width: '100%',
		background: '#6F4898',
		color: 'white',
		border: 'none',
		borderRadius: '8px',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '6px',
		fontSize: '14px',
		fontWeight: '500',
		transition: 'background 0.2s',
		'&:hover': {
			background: '#5d3d80'
		}
	},
	albumList: {
		display: 'flex',
		flexDirection: 'column',
		gap: '8px'
	},
	// Base album item styles - unselected state
	albumItem: {
		background: 'white',
		borderRadius: '8px',
		transition: 'all 0.2s',
		cursor: 'pointer',
		border: '1px solid #E0E0E0',
		padding: '8px 12px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: '8px',
		minHeight: '40px'
	},
	// Hover state for unselected albums
	albumItemHover: {
		'&:hover': {
			background: '#F8F5FF',
			borderColor: '#E0E0E0'
		}
	},
	// Selected album item
	albumItemSelected: {
		background: '#F0E6FF',
		border: '1px solid #6F4898',
		boxShadow: '0 2px 4px rgba(111, 72, 152, 0.1)'
	},
	albumContent: {
		flex: 1,
		minWidth: 0
	},
	albumName: {
		margin: 0,
		fontSize: '14px',
		fontWeight: 500,
		color: '#333',
		wordBreak: 'break-word',
		lineHeight: '1.3'
	},
	actionButtons: {
		display: 'flex',
		gap: '4px',
		flexShrink: 0
	},
	iconButton: {
		width: '24px',
		height: '24px',
		padding: '4px',
		border: 'none',
		borderRadius: '4px',
		background: 'transparent',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#666',
		transition: 'all 0.2s',
		'&:hover': {
			background: '#E6D6FF',
			color: '#6F4898'
		}
	},
	guestUploadsSection: {
		borderRadius: '8px',
		transition: 'all 0.2s',
		cursor: 'pointer',
		border: '1px solid #FFD466',
		padding: '8px 12px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: '8px',
		minHeight: '40px',
		background: '#FFF9E6',
		transition: 'background 0.2s',
		'&:hover': {
			background: '#FFD466'
		}
	},
	guestUploadsHeader: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px'
	},
	guestUploadsTitle: {
		margin: 0,
		fontSize: '16px',
		fontWeight: '600'
	},
	modalContent: {
		marginTop: '16px'
	}
};

const AlbumListComp = ({
	albums,
	onAlbumClick,
	onGuestImageClick,
	createAlbum,
	albumNameEdited,
	deleteAlbum,
	visibleEvent,
	albumSortOrderChanged,
	deletingAlbums,
	clickedAlbum,
	guestImageCount,
	guestUploadsLoading
}) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
	const [newAlbumName, setNewAlbumName] = useState('');
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [selectedAlbum, setSelectedAlbum] = useState(null);
	const [editingName, setEditingName] = useState('');

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 576);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleDragEnd = async (result) => {
		if (!result.destination) return;
		const oldIndex = result.source.index;
		const newIndex = result.destination.index;
		if (oldIndex === newIndex) return;

		try {
			await albumSortOrderChanged(oldIndex, newIndex);
		} catch (e) {
			message.error('Failed to reorder albums');
		}
	};

	const handleCreateAlbum = () => {
		if (!newAlbumName.trim()) return;
		createAlbum(newAlbumName);
		setNewAlbumName('');
	};

	const handleEditAlbum = () => {
		if (!editingName.trim() || editingName === selectedAlbum.name) {
			setEditModalVisible(false);
			return;
		}

		const index = albums.findIndex((album) => album.id === selectedAlbum.id);
		albumNameEdited(index, visibleEvent, editingName, selectedAlbum);
		setEditModalVisible(false);
	};

	const handleDeleteAlbum = () => {
		const index = albums.findIndex((album) => album.id === selectedAlbum.id);
		deleteAlbum(index, visibleEvent, selectedAlbum);
		setDeleteModalVisible(false);
	};

	return (
		<div style={commonStyles.container}>
			{!isMobile && (
				<div style={commonStyles.header}>
					<LayoutGrid size={20} color="#6F4898" />
					<h4 style={commonStyles.headerText}>
						ALBUMS
						<span style={commonStyles.albumCount}>({albums?.length || 0})</span>
					</h4>
				</div>
			)}

			<div style={commonStyles.addAlbumContainer}>
				<Input
					value={newAlbumName}
					onChange={(e) => setNewAlbumName(e.target.value)}
					placeholder="Add new album"
					style={commonStyles.input}
					maxLength={ALBUM_NAME_LENGTH}
					onPressEnter={handleCreateAlbum}
				/>
				<button onClick={handleCreateAlbum} style={commonStyles.addButton}>
					<Plus size={16} />
					Add Album
				</button>
			</div>

			<p style={{ fontSize: '12px', color: '#666', margin: '-8px 0 0 4px', fontStyle: 'italic' }}>
				You can drag and drop album names to rearrange their display order
			</p>

			{albums && (
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="albums">
						{(provided) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={commonStyles.albumList}>
								{albums.map((album, index) => (
									<Draggable key={album.id} draggableId={album.id} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={{
													...commonStyles.albumItem,
													...(album.id !== clickedAlbum?.id && commonStyles.albumItemHover),
													...(album.id === clickedAlbum?.id ? commonStyles.albumItemSelected : {}),
													...provided.draggableProps.style,
													...(snapshot.isDragging && {
														background: '#F0E6FF',
														boxShadow: '0 2px 8px rgba(111, 72, 152, 0.15)'
													})
												}}
												onClick={() => onAlbumClick(visibleEvent, album)}>
												<div style={commonStyles.albumContent}>
													<h3 style={commonStyles.albumName}>{album.name}</h3>
												</div>
												{album.id === clickedAlbum?.id && (
													<div style={commonStyles.actionButtons}>
														<button
															style={commonStyles.iconButton}
															onClick={(e) => {
																e.stopPropagation();
																setSelectedAlbum(album);
																setEditingName(album.name);
																setEditModalVisible(true);
															}}>
															<Pencil size={14} />
														</button>
														<button
															style={commonStyles.iconButton}
															onClick={(e) => {
																e.stopPropagation();
																setSelectedAlbum(album);
																setDeleteModalVisible(true);
															}}>
															<Trash2 size={14} />
														</button>
													</div>
												)}
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			)}

			{!guestUploadsLoading && (visibleEvent?.isGuestUploadEnabled || guestImageCount > 0) && (
				<div
					style={commonStyles.guestUploadsSection}
					onClick={() =>
						onGuestImageClick(visibleEvent, {
							isGuestUploads: true,
							name: 'Guest Uploads',
							id: 'guestUploads'
						})
					}>
					<div style={commonStyles.guestUploadsHeader}>
						<Users size={20} color="#6F4898" />
						<h2 style={commonStyles.guestUploadsTitle}>Guest Uploads</h2>
					</div>
				</div>
			)}

			{/* Edit Album Modal */}
			<Modal
				title="Edit Album Name"
				open={editModalVisible}
				onOk={handleEditAlbum}
				onCancel={() => setEditModalVisible(false)}
				okText="Save"
				cancelText="Cancel">
				<div style={commonStyles.modalContent}>
					<Input
						value={editingName}
						onChange={(e) => setEditingName(e.target.value)}
						maxLength={ALBUM_NAME_LENGTH}
						placeholder="Enter new album name"
					/>
				</div>
			</Modal>

			{/* Delete Album Modal */}
			<Modal
				title="Delete Album"
				open={deleteModalVisible}
				onOk={handleDeleteAlbum}
				onCancel={() => setDeleteModalVisible(false)}
				okText="Delete"
				cancelText="Cancel"
				okButtonProps={{ danger: true }}>
				<div style={commonStyles.modalContent}>
					<p>Are you sure you want to delete this album? All photos inside will be deleted.</p>
				</div>
			</Modal>
		</div>
	);
};

AlbumListComp.propTypes = {
	albums: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		}).isRequired
	),
	onAlbumClick: PropTypes.func.isRequired,
	onGuestImageClick: PropTypes.func.isRequired,
	createAlbum: PropTypes.func.isRequired,
	albumNameEdited: PropTypes.func.isRequired,
	deleteAlbum: PropTypes.func.isRequired,
	visibleEvent: PropTypes.object.isRequired,
	albumSortOrderChanged: PropTypes.func.isRequired,
	deletingAlbums: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AlbumListComp;
