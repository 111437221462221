import { Radio, Space } from 'antd';
import { Ban, DownloadCloud, User } from 'lucide-react';
import React from 'react';

const DownloadOptionsCompact = ({ value = 'all', onChange }) => {
	const styles = {
		option: (isSelected) => ({
			display: 'flex',
			alignItems: 'center',
			gap: '8px',
			padding: '8px 12px',
			background: isSelected ? 'rgba(111, 72, 152, 0.05)' : 'transparent',
			borderRadius: '6px',
			cursor: 'pointer',
			transition: 'all 0.2s ease'
		}),
		iconWrapper: (isSelected) => ({
			width: '24px',
			height: '24px',
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			background: isSelected ? 'rgba(111, 72, 152, 0.1)' : 'rgba(111, 72, 152, 0.06)',
			transition: 'all 0.2s ease'
		}),
		icon: (isSelected) => ({
			width: '14px',
			height: '14px',
			color: '#6F4898',
			opacity: isSelected ? 1 : 0.7
		}),
		label: {
			fontSize: '14px',
			color: '#2D3748'
		}
	};

	const options = [
		{
			value: 'all',
			label: 'Allow all',
			icon: <DownloadCloud style={styles.icon(value === 'all')} />
		},
		{
			value: 'myPhotos',
			label: 'Personal photos only',
			icon: <User style={styles.icon(value === 'myPhotos')} />
		},
		{
			value: 'none',
			label: 'Disable downloads',
			icon: <Ban style={styles.icon(value === 'none')} />
		}
	];

	return (
		<div>
			<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500, marginBottom: '12px' }}>
				Download Options For App and Weblink
			</div>
			<Radio.Group value={value} onChange={onChange} style={{ width: '100%' }}>
				<Space direction="vertical" style={{ width: '100%' }}>
					{options.map((option) => (
						<Radio key={option.value} value={option.value} style={{ width: '100%' }}>
							<div style={styles.option(value === option.value)}>
								<div style={styles.iconWrapper(value === option.value)}>{option.icon}</div>
								<span style={styles.label}>{option.label}</span>
							</div>
						</Radio>
					))}
				</Space>
			</Radio.Group>
		</div>
	);
};

export default DownloadOptionsCompact;
