import { Button, Card, Col, Modal, Row, Typography } from 'antd';
import { ArrowRight, Info, LifeBuoy, Mail, Phone, UserCircle } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import whatsappBlackLogo from '../svg/whatsapp-black-logo.svg';
import whatsappIcon from '../svg/whatsapp-icon.svg';

const { Text, Title, Paragraph } = Typography;

// Shared styles object
const styles = {
	card: {
		height: '100%',
		transition: 'all 0.3s ease',
		cursor: 'pointer',
		borderRadius: '12px',
		boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
		border: '1px solid #f0f0f0'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		gap: '12px',
		height: '100%'
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px',
		marginBottom: '4px'
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '8px',
		height: '40px',
		borderRadius: '8px',
		fontSize: '15px',
		fontWeight: '500'
	},
	whatsappIcon: {
		width: '20px',
		height: '20px'
	},
	communityLink: {
		display: 'flex',
		flexDirection: 'column',
		gap: '4px',
		cursor: 'pointer',
		transition: 'all 0.3s ease',
		textDecoration: 'none',
		marginTop: '12px',
		padding: '12px 16px',
		backgroundColor: '#F9F5FF',
		borderRadius: '8px',
		border: '1px solid #E8DDFF'
	}
};

const InfoCards = ({ whitelabelId, email }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const isWhitelabel0 = whitelabelId === 'whitelabel-0';
	const supportNumber = isWhitelabel0 ? '918799345646' : '916351081819';

	// Event handlers
	const handleModalOpen = () => setIsModalOpen(true);
	const handleModalClose = () => setIsModalOpen(false);

	const handleWhatsAppSupport = () => {
		const message = encodeURIComponent(
			`I need support #kamerodashboard (Email: ${email}${
				!isWhitelabel0 ? `, WhitelabelID: ${whitelabelId}` : ''
			})`
		);
		window.open(`https://wa.me/${supportNumber}?text=${message}`, '_blank');
		handleModalClose();
	};

	const handleWhatsAppRM = () => {
		const message = encodeURIComponent('Hi Krish, I have a query regarding Kamero services');
		window.open(`https://wa.me/916351081819?text=${message}`, '_blank');
	};

	const handleWhatsAppCommunity = () => {
		window.open('https://chat.whatsapp.com/Jy31nhEFjArBXsX8kT9tyL', '_blank');
	};

	const handleWhatsAppSubscription = () => {
		const message = encodeURIComponent('I want to buy Kamero subscription plan #kamerodashboard');
		window.open(`https://wa.me/916351081819?text=${message}`, '_blank');
	};

	// Component: Support Card
	const SupportCard = () => (
		<Card style={styles.card} bodyStyle={{ padding: '16px', height: '100%' }}>
			<div style={styles.content}>
				<div style={styles.title}>
					<LifeBuoy size={24} color="#6F4898" />
					<Title level={4} style={{ margin: 0, color: '#6F4898' }}>
						Support
					</Title>
				</div>

				<Text style={{ color: '#4A4A4A', fontSize: '14px', lineHeight: 1.5 }}>
					Need help? Contact our support team for assistance:
				</Text>

				<Button
					type="primary"
					onClick={handleModalOpen}
					style={{
						...styles.button,
						backgroundColor: '#6F4898',
						border: 'none',
						marginTop: !isWhitelabel0 ? 'auto' : undefined
					}}>
					<img src={whatsappIcon} alt="WhatsApp" style={styles.whatsappIcon} />
					<span>Contact Support</span>
				</Button>

				{isWhitelabel0 && (
					<div style={{ marginTop: '8px', paddingTop: '12px', borderTop: '1px solid #f0f0f0' }}>
						<a onClick={handleWhatsAppCommunity} style={styles.communityLink}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
								<Text strong style={{ fontSize: '15px', color: '#6F4898' }}>
									Join our WhatsApp Community
								</Text>
								<ArrowRight size={18} color="#6F4898" />
							</div>
							<Text style={{ fontSize: '14px', color: '#666', marginTop: '2px' }}>
								Get updates on offers, features & best practices
							</Text>
						</a>
					</div>
				)}
			</div>
		</Card>
	);

	// Component: Important Information Card
	const ImportantInfoCard = () => (
		<Card style={styles.card} bodyStyle={{ padding: '20px', height: '100%' }}>
			<div style={styles.content}>
				<div style={styles.title}>
					<Info size={24} color="#FFB700" />
					<Title level={4} style={{ margin: 0, color: '#FFB700' }}>
						Important Information
					</Title>
				</div>

				<div style={{ color: '#4A4A4A' }}>
					<Text
						style={{
							fontSize: '14px',
							lineHeight: 1.5,
							display: 'block',
							marginBottom: '8px'
						}}>
						Face recognition comes enabled by default for subscription-based events. For pack-based
						events, optional face recognition can be purchased separately.
					</Text>

					<Text style={{ fontSize: '14px', lineHeight: 1.6, display: 'block' }}>
						Contact us to learn about our subscription plans, best prices, and special offers!
					</Text>
				</div>

				<div style={{ marginTop: 'auto' }}>
					<Button
						type="primary"
						onClick={handleWhatsAppSubscription}
						style={{
							...styles.button,
							backgroundColor: '#FFB700',
							border: 'none',
							width: '100%',
							color: '#000000',
							fontWeight: '600'
						}}>
						<img src={whatsappBlackLogo} alt="WhatsApp" style={styles.whatsappIcon} />
						<span>Contact for Subscription</span>
					</Button>
				</div>
			</div>
		</Card>
	);

	// Component: Relationship Manager Card
	const RelationshipManagerCard = () => (
		<Card style={styles.card} bodyStyle={{ padding: '16px', height: '100%' }}>
			<div style={styles.content}>
				<div style={styles.title}>
					<UserCircle size={24} color="#FFB700" />
					<Title level={4} style={{ margin: 0, color: '#FFB700' }}>
						Your Dedicated RM
					</Title>
				</div>

				<Text style={{ color: '#4A4A4A', fontSize: '14px', lineHeight: 1.5 }}>
					Thank you for being a valuable customer! We have assigned you a dedicated relationship
					manager:
				</Text>

				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: '16px',
						marginTop: '4px',
						marginBottom: '12px'
					}}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '8px',
							minWidth: '200px'
						}}>
						<UserCircle size={20} color="#FFB700" />
						<Text strong style={{ fontSize: '15px' }}>
							Krish Shakhiya
						</Text>
					</div>

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '8px',
							flex: 1
						}}>
						<Phone size={18} color="#FFB700" />
						<Text copyable style={{ fontSize: '14px' }}>
							+91 63510 81819
						</Text>
					</div>
				</div>

				<Button
					type="primary"
					onClick={handleWhatsAppRM}
					style={{
						...styles.button,
						backgroundColor: '#FFB700',
						border: 'none',
						width: '100%',
						color: '#000000',
						fontWeight: '600'
					}}>
					<img src={whatsappBlackLogo} alt="WhatsApp" style={styles.whatsappIcon} />
					<span>Message on WhatsApp</span>
				</Button>
			</div>
		</Card>
	);

	// Component: Support Modal
	const SupportModal = () => (
		<Modal
			title={
				<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
					<LifeBuoy size={24} color="#6F4898" />
					<span style={{ color: '#6F4898', margin: 0 }}>Contact Support</span>
				</div>
			}
			open={isModalOpen}
			onCancel={handleModalClose}
			footer={null}
			width={600}>
			<div style={{ padding: '16px 0' }}>
				<Paragraph style={{ fontSize: '14px', color: '#4A4A4A', marginBottom: '16px' }}>
					When raising a support request, please:
				</Paragraph>

				<ul style={{ paddingLeft: '20px', marginBottom: '20px' }}>
					<li style={{ marginBottom: '8px', color: '#4A4A4A', fontSize: '14px' }}>
						Describe the issue you are facing in detail
					</li>
					<li style={{ marginBottom: '8px', color: '#4A4A4A', fontSize: '14px' }}>
						Include the event ID if the issue is event-related
					</li>
					<li style={{ marginBottom: '8px', color: '#4A4A4A', fontSize: '14px' }}>
						Attach relevant screenshots or screen recordings
					</li>
				</ul>

				<Button
					type="primary"
					onClick={handleWhatsAppSupport}
					style={{
						...styles.button,
						backgroundColor: '#6F4898',
						border: 'none',
						width: '100%',
						marginBottom: '16px'
					}}>
					<img src={whatsappIcon} alt="WhatsApp" style={styles.whatsappIcon} />
					<span>Contact Support on WhatsApp</span>
				</Button>

				<div
					style={{
						padding: '12px',
						backgroundColor: '#F5F5F5',
						borderRadius: '8px',
						marginBottom: '16px'
					}}>
					<Text style={{ fontSize: '14px', color: '#666' }}>
						Alternatively, you can also email us at:
					</Text>
					<a
						href="mailto:support@kamero.in"
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '8px',
							color: '#6F4898',
							marginTop: '8px',
							fontSize: '14px'
						}}>
						<Mail size={16} />
						support@kamero.in
					</a>
				</div>
				<div style={{ textAlign: 'center', marginTop: '8px' }}>
					<Text style={{ fontSize: '13px', color: '#666', display: 'block', marginBottom: '8px' }}>
						If WhatsApp is not accessible on desktop for you, you can directly message us at:
					</Text>
					<div style={{ display: 'inline-flex', alignItems: 'center' }}>
						<img
							src={whatsappIcon}
							alt="WhatsApp"
							style={{
								width: '14px',
								height: '14px',
								marginRight: '6px'
							}}
						/>
						<Text copyable style={{ fontSize: '14px', color: '#4A4A4A' }}>
							{isWhitelabel0 ? '+91 87993 45646' : '+91 63510 81819'}
						</Text>
					</div>
				</div>
			</div>
		</Modal>
	);

	return (
		<>
			<Row gutter={[16, 16]} style={{ padding: '12px', maxWidth: '1000px', margin: '0 auto' }}>
				<Col xs={24} md={12}>
					<SupportCard />
				</Col>
				<Col xs={24} md={12}>
					{isWhitelabel0 ? <ImportantInfoCard /> : <RelationshipManagerCard />}
				</Col>
			</Row>
			<SupportModal />
		</>
	);
};

InfoCards.propTypes = {
	whitelabelId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired
};

export default InfoCards;
