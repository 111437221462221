import { Modal } from 'antd';
import { Image } from 'lucide-react';

const PreviewModal = ({ open, setOpen, clickedLink }) => {
	const styles = {
		modalContent: {
			padding: '20px 0'
		},
		titleWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			gap: '8px',
			marginBottom: '24px'
		},
		title: {
			color: '#1F2937',
			fontSize: '18px',
			fontWeight: 600,
			margin: 0
		},
		imageContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			borderRadius: '8px',
			overflow: 'hidden',
			backgroundColor: '#F3F4F6'
		},
		image: {
			width: '100%',
			height: '400px', // Increased height
			objectFit: 'contain', // Changed to contain for better aspect ratio
			backgroundColor: '#000' // Dark background for transparent images
		}
	};

	return (
		<Modal
			width={720} // Increased width
			open={open}
			onCancel={() => {
				setOpen(false);
			}}
			footer={null}
			centered
			bodyStyle={{ padding: 0 }}>
			<div style={styles.modalContent}>
				<div style={styles.titleWrapper}>
					<Image color="#6F4898" size={20} />
					<h4 style={styles.title}>Thumbnail Preview</h4>
				</div>

				<div style={styles.imageContainer}>
					{clickedLink.thumbnailURL && (
						<img src={clickedLink.thumbnailURL} alt="Thumbnail Preview" style={styles.image} />
					)}
				</div>
			</div>
		</Modal>
	);
};

export default PreviewModal;
