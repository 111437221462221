// src/styles/cardStyles.js

export const commonCardStyles = {
	cardStyle: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '12px',
		boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)'
	},
	bodyStyle: {
		padding: '16px',
		height: '100%',
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column'
	},
	headerStyle: {
		fontSize: '16px',
		fontWeight: '600',
		color: '#27272A',
		textAlign: 'center',
		margin: '0 0 12px'
	},
	highlightSection: {
		backgroundColor: '#F9F5FF',
		padding: '12px',
		borderRadius: '12px',
		marginBottom: '12px'
	},
	contentSection: {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
		gap: '12px'
	},
	footerSection: {
		borderTop: '1px solid #f0f0f0',
		paddingTop: '12px',
		marginTop: 'auto',
		color: '#71717A',
		fontSize: '14px'
	},
	button: {
		height: '32px',
		borderRadius: '6px'
	},
	primaryButton: {
		backgroundColor: '#6F4898',
		borderColor: '#6F4898'
	}
};
