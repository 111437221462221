import { Button, Card, Input } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { commonCardStyles } from '../styles/cardStyles';

const { Search } = Input;

class WalletComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recharging: false,
			rechargeAmount: undefined,
			isSearchDisabled: true,
			redeeming: false,
			couponCode: undefined,
			isRedeemDisabled: true,
			accordionOpen: false,
			isMobile: window.screen.width <= 576,
			isProcessing: false // New state to track RazorPay processing
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	calculateGST = (amount) => {
		const numericAmount = typeof amount === 'number' ? amount : parseFloat(amount);
		if (isNaN(numericAmount) || numericAmount <= 0) {
			return { amount: 0, gst: 0, total: 0 };
		}
		const fixedAmount = parseFloat(numericAmount.toFixed(2));
		const gst = (fixedAmount * 0.18).toFixed(2);
		const total = (parseFloat(fixedAmount) + parseFloat(gst)).toFixed(2);
		return {
			amount: fixedAmount,
			gst: parseFloat(gst),
			total: parseFloat(total)
		};
	};

	handleRecharge = async (value) => {
		if (this.state.recharging || this.state.isProcessing) {
			return;
		}

		if (value > 0) {
			try {
				this.setState({ recharging: true, isProcessing: true });
				await this.props.recharge(value);
			} finally {
				this.setState({
					rechargeAmount: undefined,
					recharging: false,
					isSearchDisabled: true
					// Note: isProcessing will be reset when RazorPay script loads
				});
			}
		}
	};

	handleRedeem = async (value) => {
		if (this.state.redeeming || this.state.isProcessing) {
			return;
		}

		if (value.length > 0) {
			try {
				this.setState({ redeeming: true });
				await this.props.redeem(value);
			} finally {
				this.setState({
					couponCode: undefined,
					redeeming: false,
					isRedeemDisabled: true
				});
			}
		}
	};

	render() {
		const { balance } = this.props;
		const { gst, total } = this.state.rechargeAmount
			? this.calculateGST(this.state.rechargeAmount)
			: { gst: 0, total: 0 };

		const isButtonDisabled = this.state.isSearchDisabled || this.state.isProcessing;

		return (
			<Card style={commonCardStyles.cardStyle} bodyStyle={commonCardStyles.bodyStyle}>
				<div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '6px' }}>
					<h3 style={commonCardStyles.headerStyle}>Available Credits</h3>

					<div
						style={{
							backgroundColor: '#F9F5FF',
							padding: '8px',
							borderRadius: '12px',
							marginBottom: '8px',
							textAlign: 'center'
						}}>
						<h2
							style={{
								fontSize: '28px',
								margin: '0',
								color: '#6F4898',
								fontWeight: '700'
							}}>
							{balance !== undefined
								? (balance / 100).toLocaleString('en-IN', {
										minimumFractionDigits: 0,
										maximumFractionDigits: 0,
										style: 'currency',
										currency: 'INR'
								  })
								: '--'}
						</h2>
					</div>

					<div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '6px' }}>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Search
								type="number"
								step="100"
								placeholder="Amount"
								enterButton={
									<Button
										type="primary"
										disabled={isButtonDisabled}
										style={{ paddingLeft: '16px', paddingRight: '16px' }}>
										{this.state.isProcessing ? 'Processing...' : 'Buy Credits'}
									</Button>
								}
								loading={this.state.recharging}
								value={this.state.rechargeAmount}
								onChange={(e) => {
									this.setState({
										rechargeAmount: e.target.value,
										isSearchDisabled: e.target.value ? e.target.value <= 0 : true
									});
								}}
								onSearch={this.handleRecharge}
								style={{
									width: '250px',
									marginBottom: '16px'
								}}
								disabled={this.state.isProcessing}
							/>
						</div>

						{this.state.rechargeAmount && (
							<div
								style={{
									fontSize: '14px',
									color: '#71717A',
									backgroundColor: '#F4F4F5',
									padding: '8px 12px',
									borderRadius: '8px',
									textAlign: 'center'
								}}>
								<p style={{ margin: '0 0 2px' }}>
									GST (18%): ₹
									{gst.toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2
									})}
								</p>
								<p style={{ margin: '0', fontWeight: '500', color: '#27272A' }}>
									Total Payable: ₹
									{total.toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2
									})}
								</p>
							</div>
						)}
					</div>
				</div>

				<div style={commonCardStyles.footerSection}>
					<p
						style={{
							margin: '0 0 8px',
							color: '#71717A',
							fontSize: '14px',
							textAlign: 'center'
						}}>
						Got coupon code? Redeem below
					</p>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Search
							type="string"
							placeholder="Coupon Code"
							enterButton={
								<Button
									type="primary"
									disabled={this.state.isRedeemDisabled || this.state.isProcessing}
									style={{ paddingLeft: '16px', paddingRight: '16px' }}>
									Redeem
								</Button>
							}
							loading={this.state.redeeming}
							value={this.state.couponCode}
							onChange={(e) => {
								this.setState({
									couponCode: e.target.value,
									isRedeemDisabled: e.target.value ? e.target.value.length <= 0 : true
								});
							}}
							onSearch={this.handleRedeem}
							style={{ width: '250px', marginBottom: '10px' }}
							disabled={this.state.isProcessing}
						/>
					</div>
				</div>
			</Card>
		);
	}
}

WalletComp.propTypes = {
	balance: PropTypes.number,
	recharge: PropTypes.func.isRequired,
	redeem: PropTypes.func.isRequired
};

export default WalletComp;
