import { Input, Modal, Popconfirm, Tooltip } from 'antd';
import { Image, Pencil, Trash2 } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const VideoListItem = ({
	name,
	id,
	itemNameMaxLength,
	onItemClick,
	editItem,
	deleteItem,
	onThumbnailClicked
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const [hoveredButton, setHoveredButton] = useState(null);
	const [isEditModalVisible, setIsEditModalVisible] = useState(false);
	const [editedName, setEditedName] = useState(name);

	const styles = {
		itemContainer: {
			display: 'flex',
			alignItems: 'center',
			padding: '6px 8px',
			background: '#fff',
			borderRadius: '6px',
			margin: '4px 0',
			transition: 'all 0.2s',
			border: '1px solid #E5E7EB'
		},
		itemContainerHover: {
			backgroundColor: '#F9FAFB'
		},
		nameSection: {
			flex: 1,
			display: 'flex',
			alignItems: 'center',
			minWidth: 0,
			cursor: 'pointer',
			marginRight: '8px'
		},
		nameWrapper: {
			width: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap'
		},
		name: {
			fontSize: '14px',
			fontWeight: 500,
			color: '#4B5563',
			margin: 0,
			lineHeight: '20px'
		},
		actionsContainer: {
			display: 'flex',
			alignItems: 'center',
			gap: '4px'
		},
		actionButton: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '28px',
			height: '28px',
			padding: '5px',
			borderRadius: '4px',
			border: 'none',
			background: 'transparent',
			cursor: 'pointer',
			transition: 'all 0.2s',
			color: '#6B7280'
		},
		actionButtonHover: {
			background: '#F3F4F6'
		}
	};

	const handleEditSubmit = () => {
		if (editedName.trim() && editedName !== name) {
			editItem(editedName.trim());
		}
		setIsEditModalVisible(false);
	};

	const handleEditCancel = () => {
		setEditedName(name);
		setIsEditModalVisible(false);
	};

	return (
		<>
			<div
				style={{
					...styles.itemContainer,
					...(isHovered ? styles.itemContainerHover : {})
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}>
				<div style={styles.nameSection} onClick={onItemClick}>
					<div style={styles.nameWrapper}>
						<Tooltip title={name} mouseEnterDelay={0.5}>
							<span style={styles.name}>{name}</span>
						</Tooltip>
					</div>
				</div>
				<div style={styles.actionsContainer}>
					<button
						style={{
							...styles.actionButton,
							...(hoveredButton === 'thumbnail' ? styles.actionButtonHover : {})
						}}
						onClick={(e) => {
							e.stopPropagation();
							onThumbnailClicked();
						}}
						onMouseEnter={() => setHoveredButton('thumbnail')}
						onMouseLeave={() => setHoveredButton(null)}
						title="Upload thumbnail">
						<Image size={16} />
					</button>
					<button
						style={{
							...styles.actionButton,
							...(hoveredButton === 'edit' ? styles.actionButtonHover : {})
						}}
						onClick={(e) => {
							e.stopPropagation();
							setIsEditModalVisible(true);
						}}
						onMouseEnter={() => setHoveredButton('edit')}
						onMouseLeave={() => setHoveredButton(null)}
						title="Edit video">
						<Pencil size={16} />
					</button>
					<Popconfirm
						title="Delete Video"
						description="Are you sure you want to delete this video?"
						okText="Delete"
						cancelText="Cancel"
						onConfirm={(e) => {
							e?.stopPropagation();
							deleteItem();
						}}
						okButtonProps={{ danger: true }}>
						<button
							style={{
								...styles.actionButton,
								...(hoveredButton === 'delete' ? styles.actionButtonHover : {})
							}}
							onClick={(e) => e.stopPropagation()}
							onMouseEnter={() => setHoveredButton('delete')}
							onMouseLeave={() => setHoveredButton(null)}
							title="Delete video">
							<Trash2 size={16} />
						</button>
					</Popconfirm>
				</div>
			</div>

			<Modal
				title="Edit Video Name"
				open={isEditModalVisible}
				onOk={handleEditSubmit}
				onCancel={handleEditCancel}
				okText="Save"
				cancelText="Cancel">
				<Input
					value={editedName}
					onChange={(e) => setEditedName(e.target.value)}
					maxLength={itemNameMaxLength}
					placeholder="Enter video name"
					autoFocus
				/>
			</Modal>
		</>
	);
};

VideoListItem.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	itemNameMaxLength: PropTypes.number,
	onItemClick: PropTypes.func.isRequired,
	editItem: PropTypes.func.isRequired,
	deleteItem: PropTypes.func.isRequired,
	onThumbnailClicked: PropTypes.func.isRequired
};

export default VideoListItem;
