import React from 'react';
import RazorPay from './RazorPay';
import makeAsyncScriptLoader from 'react-async-script';
import { useEffect, useState } from 'react';

const URL = `https://checkout.razorpay.com/v1/checkout.js`;
const globalName = 'Razorpay';

const RazorPayWrapper = ({ isScriptLoaded, isScriptLoadSucceed, ...props }) => {
	const [key, setKey] = useState(0);

	useEffect(() => {
		if (isScriptLoaded && isScriptLoadSucceed) {
			setKey((prevKey) => prevKey + 1);
		}
	}, [isScriptLoaded, isScriptLoadSucceed]);

	return <RazorPay key={key} {...props} />;
};

export default makeAsyncScriptLoader(URL, {
	globalName: globalName
})(RazorPayWrapper);
