import { Alert, Button, Input, Modal, Typography } from 'antd';
import { Pencil } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isEditingAllowed } from '../helpers/dateHelpers';

const { Title, Text } = Typography;

const validateEventId = (eventId) => {
	// Regex to match alphanumeric characters and allowed special characters (@ -)
	const validEventIdRegex = /^[a-zA-Z0-9@-]+$/;
	return validEventIdRegex.test(eventId);
};

const EditEventIdModal = ({ event, userId, onEdit }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [newEventId, setNewEventId] = useState('');
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (isModalVisible) {
			setNewEventId(event.eventId);
			setError(null);
		}
	}, [isModalVisible, event.eventId]);

	const showModal = () => setIsModalVisible(true);
	const handleCancel = () => setIsModalVisible(false);

	const handleEdit = async () => {
		if (newEventId.trim() === '') {
			setError('Event ID cannot be empty.');
			return;
		}

		if (newEventId.trim() === event.eventId.trim()) {
			setError('New event ID must be different from the current ID.');
			return;
		}

		if (!validateEventId(newEventId)) {
			setError(
				'Event ID can only contain letters, numbers, and the special characters @ and -. Spaces are not allowed.'
			);
			return;
		}

		setIsLoading(true);
		try {
			await onEdit(userId, event, newEventId);
			setIsModalVisible(false); // Only close on success
		} catch (err) {
			console.error('Failed to update event ID: ', err);
			// Set error message from API response
			setError(err.message || 'Failed to update event ID. Please try a different ID.');
		} finally {
			setIsLoading(false);
		}
	};

	const renderEditButton = () => (
		<Pencil
			style={{
				height: '24px',
				width: '24px',
				cursor: 'pointer',
				paddingLeft: '4px',
				paddingRight: '4px',
				paddingTop: '8px'
			}}
			onClick={showModal}
		/>
	);

	const renderModalContent = () => {
		if (!isEditingAllowed(event.createdAt)) {
			return (
				<Alert
					message="Event ID cannot be edited"
					description={
						<>
							For security reasons, the event ID cannot be edited after 60 days from creation. If
							you need to change it, please contact support at{' '}
							<a href="mailto:support@kamero.in">support@kamero.in</a>.
						</>
					}
					type="info"
					showIcon
				/>
			);
		}

		return (
			<>
				<Alert
					message="Important Information About Event IDs"
					description={
						<>
							<p>
								The Event ID is a unique identifier for your event that helps users easily access
								and remember it. When choosing an Event ID:
							</p>
							<ul>
								<li>Use simple, memorable combinations of letters and numbers</li>
								<li>Only alphanumeric characters (A-Z, 0-9) are allowed</li>
								<li>Only special characters @ and - are permitted</li>
								<li>Spaces are not allowed</li>
								<li>Keep it short but meaningful (e.g., "Summit2024", "Team-Day@HQ")</li>
							</ul>
							<p>
								Note: Changing the event ID will update the event QR code and event link. You'll
								need to share the new QR code or link with your users/clients. Previous links and QR
								codes will no longer work.
							</p>
							<p>
								Important: Event IDs can only be modified within the first 60 days after event
								creation. After this period, you'll need to contact support for any ID changes.
							</p>
						</>
					}
					type="info"
					showIcon
					style={{ marginBottom: '16px' }}
				/>
				<Text>Enter the new ID for the event:</Text>
				<Input
					value={newEventId}
					onChange={(e) => {
						setNewEventId(e.target.value);
						setError(null); // Clear error when user starts typing
					}}
					placeholder="New Event ID"
					maxLength={50}
					style={{ marginTop: '8px', marginBottom: '16px' }}
				/>
				{error && <Alert message={error} type="error" showIcon style={{ marginBottom: '16px' }} />}
			</>
		);
	};

	const modalFooter = isEditingAllowed(event.createdAt)
		? [
				<Button key="back" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={handleEdit}>
					Save Changes
				</Button>
		  ]
		: [
				<Button key="back" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button key="support" type="primary" href="mailto:support@kamero.in">
					Contact Support
				</Button>
		  ];

	return (
		<>
			{renderEditButton()}
			<Modal
				title={<Title level={4}>Edit Event ID</Title>}
				open={isModalVisible}
				onCancel={handleCancel}
				footer={modalFooter}>
				{renderModalContent()}
			</Modal>
		</>
	);
};

EditEventIdModal.propTypes = {
	event: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired,
	onEdit: PropTypes.func.isRequired
};

export default EditEventIdModal;
