import { Col, Form, Radio, Row } from 'antd';
import { Ban, DownloadCloud, User } from 'lucide-react';
import React from 'react';

const DownloadOptions = ({ value = 'all', onChange }) => {
	const styles = {
		radioWrapper: {
			margin: 0,
			padding: 0,
			width: '100%'
		},
		card: (isSelected) => ({
			background: '#fff',
			border: '1px solid #6F4898',
			borderRadius: '8px',
			padding: '8px 16px',
			transition: 'all 0.2s ease',
			cursor: 'pointer',
			height: '40px',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: isSelected ? 'rgba(111, 72, 152, 0.02)' : '#fff',
			width: '100%'
		}),
		content: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			width: '100%',
			gap: '12px',
			minWidth: '180px'
		},
		iconWrapper: (isSelected) => ({
			width: '28px',
			height: '28px',
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			background: isSelected ? 'rgba(111, 72, 152, 0.1)' : 'rgba(111, 72, 152, 0.06)',
			transition: 'all 0.2s ease',
			flexShrink: 0
		}),
		icon: (isSelected) => ({
			width: '16px',
			height: '16px',
			color: '#6F4898',
			opacity: isSelected ? 1 : 0.7,
			transition: 'all 0.2s ease'
		}),
		title: (isSelected) => ({
			fontSize: '14px',
			transition: 'all 0.2s ease',
			whiteSpace: 'nowrap'
		}),
		radioGroup: {
			width: '100%'
		},
		row: {
			width: '100%',
			display: 'flex'
		}
	};

	const options = [
		{
			value: 'all',
			title: 'Allow all',
			icon: <DownloadCloud style={styles.icon(value === 'all')} />
		},
		{
			value: 'myPhotos',
			title: 'Personal photos only',
			icon: <User style={styles.icon(value === 'myPhotos')} />
		},
		{
			value: 'none',
			title: 'Disable downloads',
			icon: <Ban style={styles.icon(value === 'none')} />
		}
	];

	return (
		<Form.Item
			name="downloadOption"
			label="Download Options"
			initialValue="all"
			tooltip="select how users can download photos for this event"
			rules={[{ required: true, message: 'Please select a download option' }]}>
			<Radio.Group value={value} onChange={onChange} defaultValue="all" style={styles.radioGroup}>
				<Row style={styles.row} gutter={[12, 12]}>
					{options.map((option) => (
						<Col xs={24} sm={24} md={8} flex="1" key={option.value}>
							<Radio value={option.value} style={styles.radioWrapper}>
								<div style={styles.card(value === option.value)}>
									<div style={styles.content}>
										<div style={styles.iconWrapper(value === option.value)}>{option.icon}</div>
										<span style={styles.title(value === option.value)}>{option.title}</span>
									</div>
								</div>
							</Radio>
						</Col>
					))}
				</Row>
			</Radio.Group>
		</Form.Item>
	);
};

export default DownloadOptions;
