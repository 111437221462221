import { Card, Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { commonCardStyles } from '../styles/cardStyles';
import { calculateActualUploadLimit } from '../utils/subscriptionUtils';

// Format large numbers to k/M format
const formatLargeNumber = (num) => {
	if (num >= 1000000) {
		return `${(num / 1000000).toFixed(1)}M`;
	}
	if (num >= 1000) {
		return `${(num / 1000).toFixed(0)}k`;
	}
	return num.toString();
};

const SubscriptionCard = ({ subscription }) => {
	const formatExpiryDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-GB', {
			day: 'numeric',
			month: 'short',
			year: 'numeric'
		});
	};

	const getDaysLeft = (dateString) => {
		const now = new Date();
		const expiry = new Date(dateString);
		const timeDiff = expiry.getTime() - now.getTime();
		const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return daysLeft > 0 ? daysLeft : 0;
	};

	const actualUploadLimit = calculateActualUploadLimit(subscription);

	return (
		<Card style={commonCardStyles.cardStyle} bodyStyle={commonCardStyles.bodyStyle}>
			<div>
				<h3 style={commonCardStyles.headerStyle}>Subscription Details</h3>

				<h3
					style={{
						fontSize: '16px',
						margin: '0 0 8px',
						color: '#27272A',
						fontWeight: '600',
						textAlign: 'center'
					}}>
					{subscription.name}
				</h3>

				<div
					style={{
						backgroundColor: '#F9F5FF',
						padding: '8px 12px',
						borderRadius: '12px',
						marginBottom: '8px'
					}}>
					<p style={{ margin: '0 0 4px', display: 'flex', alignItems: 'center', gap: '4px' }}>
						<strong>Status:</strong>
						<Tag color={subscription.status === 'active' ? 'green' : 'red'}>
							{subscription.status}
						</Tag>
					</p>
					<p style={{ margin: '0' }}>
						<strong>Valid till:</strong> {formatExpiryDate(subscription.expiresAt)}{' '}
						<span style={{ color: '#71717A' }}>
							({getDaysLeft(subscription.expiresAt)} days left)
						</span>
					</p>
				</div>

				<div
					style={{
						fontSize: '14px',
						borderTop: '1px solid #f0f0f0',
						paddingTop: '8px',
						display: 'flex',
						flexDirection: 'column',
						gap: '4px'
					}}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Upload Capacity:</span>
						<span
							style={{
								color: actualUploadLimit <= 0 ? '#ff4d4f' : '#27272A',
								fontWeight: '500'
							}}>
							{actualUploadLimit} / {formatLargeNumber(subscription.uploadLimit)}
							{actualUploadLimit <= 0 && (
								<span style={{ marginLeft: '4px', fontSize: '12px', color: '#ff4d4f' }}>
									(Limit reached)
								</span>
							)}
						</span>
					</div>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Current Photos:</span>
						<span
							style={{
								color:
									subscription.currentPhotosCount >= subscription.maxPhotosLimit
										? '#ff4d4f'
										: '#27272A',
								fontWeight: '500'
							}}>
							{subscription.currentPhotosCount} / {formatLargeNumber(subscription.maxPhotosLimit)}
						</span>
					</div>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Reserved for Guests:</span>
						<span style={{ color: '#27272A', fontWeight: '500' }}>
							{subscription.guestPhotosLimit}
						</span>
					</div>
				</div>
			</div>

			<div
				style={{
					borderTop: '1px solid #f0f0f0',
					paddingTop: '8px',
					color: '#71717A',
					fontSize: '14px'
				}}>
				<strong>Features:</strong> Access to all features
			</div>
		</Card>
	);
};

SubscriptionCard.propTypes = {
	subscription: PropTypes.shape({
		name: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		tier: PropTypes.string.isRequired,
		expiresAt: PropTypes.string.isRequired,
		maxPhotosLimit: PropTypes.number.isRequired,
		uploadLimit: PropTypes.number.isRequired,
		uploadedPhotosCount: PropTypes.number.isRequired,
		currentPhotosCount: PropTypes.number.isRequired,
		guestPhotosLimit: PropTypes.number.isRequired,
		guestPhotosCount: PropTypes.number.isRequired
	}).isRequired
};

export default SubscriptionCard;
