import { Button, Input, InputNumber, message, Modal, Switch } from 'antd';
import { Download } from 'lucide-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { refreshVisibleEvent } from '../actions/pouchDBActions';
import Event from '../pouchDB/models/Event';
import DownloadOptionsCompact from './DownloadOptionsCompact';

class EventSettings extends Component {
	constructor(props) {
		super(props);
		// Initialize state with props.event values
		const event = props.event;

		this.state = {
			localError: undefined,
			isPrivateFaces: event.isPrivateFaces,
			isRegistrationRequired: event.isRegistrationRequired,
			isGuestUploadEnabled: event.isGuestUploadEnabled,
			isEventDisabled: event.isEventDisabled,
			allowEnquiry: event.allowEnquiry !== undefined ? event.allowEnquiry : true,
			loading: false,
			shareDetail: undefined,
			email: '',
			emailInput: '',
			emailError: '',
			showDownloadForm: false,
			copied: 0,
			// Initialize download settings from event props
			// isDownloadOff: event.isDownloadOff || false,
			// isDownloadMyPhotosOff: event.isDownloadMyPhotosOff || false,
			newGuestMaxphotos: event.guestMaxPhotos,
			downloadOption: 'all'
		};
	}

	async componentDidMount() {
		await this.fetchEventData();
		await this.fetchData();
	}

	determineDownloadOption = (eventData) => {
		if (eventData.isDownloadOff && eventData.isDownloadMyPhotosOff) {
			return 'none';
		} else if (eventData.isDownloadOff && !eventData.isDownloadMyPhotosOff) {
			return 'myPhotos';
		}
		return 'all';
	};

	handleDownloadOptionChange = (e) => {
		this.setState({ downloadOption: e.target.value });
	};

	error() {
		const { lastError } = this.props;
		return this.state.localError || lastError ? (
			<p style={{ color: '#FF7981', fontSize: '12px' }}>
				{lastError ? lastError : this.state.localError}
			</p>
		) : null;
	}

	fetchEventData = async () => {
		try {
			const eventData = await Event.fetchEvent(this.props.event.id);
			if (eventData) {
				// Determine download option based on the flags
				const downloadOption = this.determineDownloadOption(eventData);

				this.setState({
					downloadOption,
					loading: false
				});
			}
		} catch (err) {
			console.error('Error fetching event data:', err);
			this.setState({ loading: false });
		}
	};

	fetchData = async () => {
		const { event, fetchShareDetail } = this.props;
		try {
			this.setState({ loading: true });
			let shareDetail = await fetchShareDetail(event.id);
			if (shareDetail) {
				this.setState({ loading: false, shareDetail: shareDetail });
			} else {
				this.setState({ loading: false });
			}
		} catch (err) {
			this.setState({ loading: false });
		}
	};

	handleEmailChange = (e) => {
		this.setState({ emailInput: e.target.value });
	};

	validateEmail = (email) => {
		const emailError = email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
			? ''
			: 'Invalid email address';
		this.setState({ email, emailError });
		return !emailError;
	};

	copyToClipBoard = (item, text) => {
		const textarea = document.createElement('textarea');
		document.body.appendChild(textarea);
		textarea.value = text;
		textarea.select();
		textarea.setSelectionRange(0, 99999);
		document.execCommand('copy');
		document.body.removeChild(textarea);
		this.setState({ copied: item });
		setTimeout(() => {
			this.setState({ copied: 0 });
		}, 3000);
	};

	// handleSave = async () => {
	// 	const { userId, event, onComplete, updateEventBoolKey, updateNewGuestMaxPhotos } = this.props;

	// 	try {
	// 		let isDownloadOff = false;
	// 		let isDownloadMyPhotosOff = false;

	// 		switch (this.state.downloadOption) {
	// 			case 'all':
	// 				// Both flags remain false
	// 				break;
	// 			case 'myPhotos':
	// 				isDownloadOff = true;
	// 				isDownloadMyPhotosOff = false;
	// 				break;
	// 			case 'none':
	// 				isDownloadOff = true;
	// 				isDownloadMyPhotosOff = true;
	// 				break;
	// 		}

	// 		const updates = [
	// 			{ key: 'isPrivateFaces', value: this.state.isPrivateFaces },
	// 			{ key: 'isRegistrationRequired', value: this.state.isRegistrationRequired },
	// 			{ key: 'isGuestUploadEnabled', value: this.state.isGuestUploadEnabled },
	// 			{ key: 'isEventDisabled', value: this.state.isEventDisabled },
	// 			{ key: 'allowEnquiry', value: this.state.allowEnquiry },
	// 			{ key: 'isDownloadOff', value: isDownloadOff },
	// 			{ key: 'isDownloadMyPhotosOff', value: isDownloadMyPhotosOff }
	// 		];

	// 		// Process all updates sequentially
	// 		for (const update of updates) {
	// 			// Add console logs to debug the update process
	// 			console.log('Checking update for:', update.key);
	// 			console.log('Current value:', event[update.key]);
	// 			console.log('New value:', update.value);

	// 			// Only update if the value has changed
	// 			if (event[update.key] !== update.value) {
	// 				console.log(`Updating ${update.key} to ${update.value}`);
	// 				await updateEventBoolKey(userId, event.id, update.key, update.value);
	// 			}
	// 		}
	// 		// Handle guest max photos update
	// 		if (
	// 			this.state.isGuestUploadEnabled &&
	// 			this.state.newGuestMaxphotos !== undefined &&
	// 			this.state.newGuestMaxphotos !== event.guestMaxPhotos
	// 		) {
	// 			await updateNewGuestMaxPhotos(userId, event.id, this.state.newGuestMaxphotos);
	// 		}

	// 		message.success('Settings saved successfully');

	// 		onComplete(true);
	// 	} catch (e) {
	// 		console.error('Error saving settings:', e);
	// 		onComplete(false);
	// 		message.error(e?.response?.body?.message || 'Failed to update settings');
	// 	}
	// };

	handleSave = async () => {
		const { userId, event, onComplete, updateEventBoolKey, updateNewGuestMaxPhotos } = this.props;

		try {
			// Determine download flags based on selected option
			let isDownloadOff = false;
			let isDownloadMyPhotosOff = false;

			switch (this.state.downloadOption) {
				case 'all':
					// Both flags remain false
					break;
				case 'myPhotos':
					isDownloadOff = true;
					isDownloadMyPhotosOff = false;
					break;
				case 'none':
					isDownloadOff = true;
					isDownloadMyPhotosOff = true;
					break;
			}

			// Add debug logs
			console.log('Current event state:', {
				isDownloadOff: event.isDownloadOff,
				isDownloadMyPhotosOff: event.isDownloadMyPhotosOff
			});
			console.log('New values:', {
				isDownloadOff,
				isDownloadMyPhotosOff
			});

			const updates = [
				{ key: 'isPrivateFaces', value: this.state.isPrivateFaces },
				{ key: 'isRegistrationRequired', value: this.state.isRegistrationRequired },
				{ key: 'isGuestUploadEnabled', value: this.state.isGuestUploadEnabled },
				{ key: 'isEventDisabled', value: this.state.isEventDisabled },
				{ key: 'allowEnquiry', value: this.state.allowEnquiry },
				{ key: 'isDownloadOff', value: isDownloadOff },
				{ key: 'isDownloadMyPhotosOff', value: isDownloadMyPhotosOff }
			];

			// Process all updates sequentially
			for (const update of updates) {
				// Convert both values to boolean for comparison
				const currentValue = Boolean(event[update.key]);
				const newValue = Boolean(update.value);

				// Add debug logs
				console.log(`Comparing ${update.key}:`, {
					current: currentValue,
					new: newValue,
					currentRaw: event[update.key],
					newRaw: update.value
				});

				// Only update if the values are different
				if (currentValue !== newValue) {
					console.log(`Updating ${update.key} from ${currentValue} to ${newValue}`);
					await updateEventBoolKey(userId, event.id, update.key, newValue);
				}
			}

			// Handle guest max photos update
			if (
				this.state.isGuestUploadEnabled &&
				this.state.newGuestMaxphotos !== undefined &&
				this.state.newGuestMaxphotos !== event.guestMaxPhotos
			) {
				await updateNewGuestMaxPhotos(userId, event.id, this.state.newGuestMaxphotos);
			}

			message.success('Settings saved successfully');
			onComplete(true);
		} catch (e) {
			console.error('Error saving settings:', e);
			onComplete(false);
			message.error(e?.response?.body?.message || 'Failed to update settings');
		}
	};
	// 	render() {
	// 		const {
	// 			userId,
	// 			event,
	// 			onComplete,
	// 			updateEventBoolKey,
	// 			updateNewGuestMaxPhotos,
	// 			downloadAllPhotos
	// 		} = this.props;
	// 		const { emailInput, emailError, showDownloadForm, shareDetail } = this.state;
	// 		const hasDownloadPin = shareDetail && shareDetail.downloadPin;
	// 		var title = 'Event Settings';
	// 		return (
	// 			<Modal
	// 				// className="event-settings-model"
	// 				title={title}
	// 				open
	// 				okText="Save"
	// 				onOk={this.handleSave}
	// 				onCancel={() => onComplete(false)}>
	// 				<div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-start' }}>
	// 					{event.hasOwnProperty('facesEnabledAt') && event.facesEnabledAt !== null && (
	// 						<>
	// 							<div
	// 								style={{
	// 									width: '100%',
	// 									display: 'flex',
	// 									flexFlow: 'row',
	// 									alignItems: 'center',
	// 									justifyContent: 'space-between'
	// 								}}>
	// 								<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>
	// 									Face-recognition based Privacy
	// 								</div>
	// 								<Switch
	// 									checked={this.state.isPrivateFaces}
	// 									onChange={(checked) => {
	// 										this.setState({ isPrivateFaces: checked });
	// 									}}
	// 								/>
	// 							</div>
	// 							<p style={{ marginBottom: '12px' }}>
	// 								Guests can view only their photos by clicking a selfie. No access to others' photos.
	// 							</p>
	// 							<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
	// 								<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
	// 							</div>
	// 						</>
	// 					)}
	// 					{this.state.isPrivateFaces === true && event.isFacesSupported === true && (
	// 						<>
	// 							<div
	// 								style={{
	// 									width: '100%',
	// 									display: 'flex',
	// 									flexFlow: 'row',
	// 									alignItems: 'center',
	// 									justifyContent: 'space-between'
	// 								}}>
	// 								<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>
	// 									PIN to view All photos from this Private Event
	// 								</div>
	// 							</div>

	// 							<div
	// 								className="share-copy-wrapper"
	// 								style={{
	// 									marginTop: '6px',
	// 									marginBottom: '4px',
	// 									width: '100%',
	// 									backgroundColor: '#F4F4F5'
	// 								}}
	// 								onClick={() => this.copyToClipBoard(3, this.state?.shareDetail?.downloadPin)}>
	// 								<span style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 400 }}>
	// 									{this.state?.shareDetail?.downloadPin}
	// 								</span>
	// 								{this.state.copied === 3 ? (
	// 									<div className="share-copy copied">Copied!</div>
	// 								) : (
	// 									<div className="share-copy" style={{ color: '#6F4898', fontWeight: 600 }}>
	// 										COPY
	// 									</div>
	// 								)}
	// 							</div>
	// 							<p style={{ marginBottom: '12px' }}>
	// 								Share this PIN only with users who should see All the Photos from this Event
	// 							</p>
	// 							<div style={{ width: '100%', marginTop: '8px', marginBottom: '16px' }}>
	// 								<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
	// 							</div>
	// 						</>
	// 					)}
	// 					<div
	// 						style={{
	// 							width: '100%',
	// 							display: 'flex',
	// 							flexFlow: 'row',
	// 							alignItems: 'center',
	// 							justifyContent: 'space-between',
	// 							marginTop: '8px'
	// 						}}>
	// 						<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>
	// 							Guest Registration for this Event
	// 						</div>
	// 						<Switch
	// 							checked={this.state.isRegistrationRequired}
	// 							onChange={(checked) => {
	// 								this.setState({ isRegistrationRequired: checked });
	// 							}}
	// 						/>
	// 					</div>
	// 					<p style={{ marginBottom: '5px' }}>
	// 						Guests must enter their email ID and mobile number to access the photos for this event.
	// 					</p>

	// 					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
	// 						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
	// 					</div>

	// 					<div
	// 						style={{
	// 							width: '100%',
	// 							display: 'flex',
	// 							flexFlow: 'row',
	// 							alignItems: 'center',
	// 							justifyContent: 'space-between'
	// 						}}>
	// 						<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>Guest Uploads</div>
	// 						<Switch
	// 							checked={this.state.isGuestUploadEnabled}
	// 							onChange={(checked) => {
	// 								this.setState({ isGuestUploadEnabled: checked });
	// 							}}
	// 						/>
	// 					</div>
	// 					<p style={{ marginBottom: '5px' }}>If Toggeled users can upload photos to this event</p>

	// 					{this.state.isGuestUploadEnabled == true && (
	// 						<div
	// 							style={{
	// 								width: '100%',
	// 								display: 'flex',
	// 								flexFlow: 'row',
	// 								alignItems: 'center',
	// 								justifyContent: 'space-between'
	// 							}}>
	// 							<p style={{ marginBottom: '12px' }}>Edit number of photos for Guests to upload</p>
	// 							{this.error()}
	// 							<InputNumber
	// 								type="number"
	// 								min={event.maxPhoto > 0}
	// 								// max={event.maxPhotos}
	// 								defaultValue={event.guestMaxPhotos}
	// 								value={this.state.newGuestMaxphotos}
	// 								onChange={(value) => {
	// 									this.setState({ newGuestMaxphotos: value });
	// 								}}
	// 							/>
	// 						</div>
	// 					)}
	// 					<p style={{ marginBottom: '5px' }}></p>

	// 					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
	// 						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
	// 					</div>

	// 					<div
	// 						style={{
	// 							width: '100%',
	// 							display: 'flex',
	// 							flexFlow: 'row',
	// 							alignItems: 'center',
	// 							justifyContent: 'space-between'
	// 						}}>
	// 						<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>Disable Event</div>
	// 						<Switch
	// 							checked={this.state.isEventDisabled}
	// 							onChange={(checked) => {
	// 								this.setState({ isEventDisabled: checked });
	// 							}}
	// 						/>
	// 					</div>
	// 					<p style={{ marginBottom: '5px' }}>
	// 						If Toggeled Event will be no longer visible to users
	// 					</p>

	// 					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
	// 						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
	// 					</div>

	// 					{/* Add Allow Enquiry setting */}
	// 					<div
	// 						style={{
	// 							width: '100%',
	// 							display: 'flex',
	// 							flexFlow: 'row',
	// 							alignItems: 'center',
	// 							justifyContent: 'space-between'
	// 						}}>
	// 						<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>Allow Enquiry</div>
	// 						<Switch
	// 							checked={this.state.allowEnquiry}
	// 							onChange={(checked) => {
	// 								this.setState({ allowEnquiry: checked });
	// 							}}
	// 						/>
	// 					</div>
	// 					<p style={{ marginBottom: '5px' }}>
	// 						Allow event viewers to contact you about your products and services (generate leads)
	// 					</p>

	// 					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
	// 						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
	// 					</div>

	// 					{hasDownloadPin && (
	// 						<>
	// 							<div
	// 								style={{
	// 									width: '100%',
	// 									display: 'flex',
	// 									flexFlow: 'row',
	// 									alignItems: 'center',
	// 									justifyContent: 'space-between'
	// 								}}>
	// 								<div style={{ fontSize: '1rem', color: '#2D3748', fontWeight: 500 }}>
	// 									Download / Send All Photos
	// 								</div>

	// 								<Button
	// 									icon={<Download />}
	// 									onClick={() => this.setState({ showDownloadForm: !showDownloadForm })}
	// 								/>
	// 							</div>

	// 							<p style={{ marginBottom: '10px' }}>send download link to clients/users</p>

	// 							{showDownloadForm && (
	// 								<div
	// 									style={{
	// 										display: 'flex',
	// 										alignItems: 'center',
	// 										justifyContent: 'center',
	// 										marginTop: '16px'
	// 									}}>
	// 									<Input
	// 										type="email"
	// 										value={emailInput}
	// 										onChange={this.handleEmailChange}
	// 										placeholder="Enter your email"
	// 										style={{ marginRight: '8px', width: '240px' }}
	// 									/>
	// 									<Button
	// 										type="primary"
	// 										onClick={() => {
	// 											const isValidEmail = this.validateEmail(emailInput);
	// 											if (isValidEmail) {
	// 												downloadAllPhotos(event.id, emailInput, shareDetail.downloadPin);
	// 											}
	// 										}}
	// 										disabled={emailError}>
	// 										Download
	// 									</Button>
	// 								</div>
	// 							)}
	// 							{emailError && (
	// 								<p style={{ color: 'red', textAlign: 'center', marginTop: '8px' }}>{emailError}</p>
	// 							)}
	// 						</>
	// 					)}

	// 					<div style={{ width: '100%', marginTop: '8px', marginBottom: '10px' }}>
	// 						<div style={{ height: '1px', width: '100%', backgroundColor: '#D4D4D8' }} />
	// 					</div>

	// 					<div className="settings-section">
	// 						<DownloadOptionsCompact
	// 							value={this.state.downloadOption}
	// 							onChange={this.handleDownloadOptionChange}
	// 						/>
	// 					</div>

	// 					{/* <div style={{ width: '100%', marginTop: '8px', marginBottom: '8px' }}>
	// 						<Checkbox
	// 							checked={this.state.isDownloadOff}
	// 							onChange={(e) => this.setState({ isDownloadOff: e.target.checked })}>
	// 							Turn off photo downloads
	// 						</Checkbox>
	// 					</div>

	// 					<div style={{ width: '100%', marginBottom: '8px' }}>
	// 						<Checkbox
	// 							checked={this.state.isDownloadMyPhotosOff}
	// 							onChange={(e) => this.setState({ isDownloadMyPhotosOff: e.target.checked })}>
	// 							Turn off My Photos downloads
	// 						</Checkbox>
	// 					</div> */}

	// 					<p style={{ marginBottom: '20px' }}></p>
	// 				</div>
	// 			</Modal>
	// 		);
	// 	}
	// }

	// EventSettings.propTypes = {
	// 	userId: PropTypes.string.isRequired,
	// 	event: PropTypes.object.isRequired,
	// 	updateEventBoolKey: PropTypes.func.isRequired,
	// 	updateNewGuestMaxPhotos: PropTypes.func.isRequired,
	// 	onComplete: PropTypes.func.isRequired,
	// 	fetchShareDetail: PropTypes.func.isRequired
	// };

	// function mapStateToProps(state) {
	// 	return {
	// 		userId: state.auth.userId,
	// 		event: state.pouchDB.visibleEvent
	// 	};
	// }

	// const mapDispatchToProps = (dispatch) => ({
	// 	fetchShareDetail: async (eventDocId) => {
	// 		return await Event.fetchShareDetail(eventDocId);
	// 	},

	// 	updateEventBoolKey: async (userId, eventId, key, value) => {
	// 		await Event.editEventBoolKey(userId, eventId, key, value);
	// 		let event = await Event.fetchEvent(eventId);
	// 		return await dispatch(refreshVisibleEvent(event));
	// 	},
	// 	updateNewGuestMaxPhotos: async (userId, eventId, value) => {
	// 		await Event.editEventGuestMaxPhotos(userId, eventId, value);
	// 		let event = await Event.fetchEvent(eventId);
	// 		return await dispatch(refreshVisibleEvent(event));
	// 	},
	// 	downloadAllPhotos: async (eventId, email, pin) => {
	// 		try {
	// 			const result = await Event.downloadAllPhotos(eventId, email, pin);
	// 			if (result.message) {
	// 				message.error(result.message);
	// 			} else {
	// 				message.success(result);
	// 			}
	// 		} catch (err) {
	// 			message.error('Something went wrong');
	// 		}
	// 	}
	// });

	// export default connect(mapStateToProps, mapDispatchToProps)(EventSettings);

	render() {
		const {
			userId,
			event,
			onComplete,
			updateEventBoolKey,
			updateNewGuestMaxPhotos,
			downloadAllPhotos
		} = this.props;
		const { emailInput, emailError, showDownloadForm, shareDetail } = this.state;
		const hasDownloadPin = shareDetail && shareDetail.downloadPin;

		return (
			<Modal
				title="Event Settings"
				open
				okText="Save"
				onOk={this.handleSave}
				onCancel={() => onComplete(false)}
				width={600} // Increased modal width
				bodyStyle={{ padding: '20px' }}>
				<div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-start', gap: '16px' }}>
					{/* Face Recognition Section */}
					{event.hasOwnProperty('facesEnabledAt') && event.facesEnabledAt !== null && (
						<>
							<div className="settings-section">
								<div className="settings-header">
									<div className="settings-title">Face-recognition based Privacy</div>
									<Switch
										checked={this.state.isPrivateFaces}
										onChange={(checked) => this.setState({ isPrivateFaces: checked })}
									/>
								</div>
								<p className="settings-description">
									Guests can view only their photos by clicking a selfie. No access to others'
									photos.
								</p>
							</div>
							<div className="settings-divider" />
						</>
					)}

					{/* PIN Section */}
					{this.state.isPrivateFaces === true && event.isFacesSupported === true && (
						<>
							<div className="settings-section">
								<div className="settings-header">
									<div className="settings-title">
										PIN to view All photos from this Private Event
									</div>
								</div>
								<div
									className="share-copy-wrapper"
									onClick={() => this.copyToClipBoard(3, this.state?.shareDetail?.downloadPin)}
									style={{
										marginTop: '6px',
										marginBottom: '4px',
										width: '100%',
										backgroundColor: '#F4F4F5',
										padding: '8px 12px',
										borderRadius: '6px',
										cursor: 'pointer',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center'
									}}>
									<span style={{ fontSize: '14px', fontWeight: 400 }}>
										{this.state?.shareDetail?.downloadPin}
									</span>
									{this.state.copied === 3 ? (
										<div className="share-copy copied">Copied!</div>
									) : (
										<div className="share-copy" style={{ color: '#6F4898', fontWeight: 600 }}>
											COPY
										</div>
									)}
								</div>
								<p className="settings-description">
									Share this PIN only with users who should see All the Photos from this Event
								</p>
							</div>
							<div className="settings-divider" />
						</>
					)}

					{/* Guest Registration Section */}
					<div className="settings-section">
						<div className="settings-header">
							<div className="settings-title">Guest Registration for this Event</div>
							<Switch
								checked={this.state.isRegistrationRequired}
								onChange={(checked) => this.setState({ isRegistrationRequired: checked })}
							/>
						</div>
						<p className="settings-description">
							Guests must enter their email ID and mobile number to access the photos for this
							event.
						</p>
					</div>
					<div className="settings-divider" />

					{/* Guest Uploads Section */}
					<div className="settings-section">
						<div className="settings-header">
							<div className="settings-title">Guest Uploads</div>
							<Switch
								checked={this.state.isGuestUploadEnabled}
								onChange={(checked) => this.setState({ isGuestUploadEnabled: checked })}
							/>
						</div>
						<p className="settings-description">If toggled users can upload photos to this event</p>
						{this.state.isGuestUploadEnabled && (
							<div className="guest-upload-limit">
								<p>Edit number of photos for Guests to upload</p>
								{this.error()}
								<InputNumber
									min={1}
									value={this.state.newGuestMaxphotos}
									onChange={(value) => this.setState({ newGuestMaxphotos: value })}
									style={{ width: '100px' }}
								/>
							</div>
						)}
					</div>
					<div className="settings-divider" />

					{/* Disable Event Section */}
					<div className="settings-section">
						<div className="settings-header">
							<div className="settings-title">Disable Event</div>
							<Switch
								checked={this.state.isEventDisabled}
								onChange={(checked) => this.setState({ isEventDisabled: checked })}
							/>
						</div>
						<p className="settings-description">
							If toggled Event will be no longer visible to users
						</p>
					</div>
					<div className="settings-divider" />

					{/* Allow Enquiry Section */}
					<div className="settings-section">
						<div className="settings-header">
							<div className="settings-title">Allow Enquiry</div>
							<Switch
								checked={this.state.allowEnquiry}
								onChange={(checked) => this.setState({ allowEnquiry: checked })}
							/>
						</div>
						<p className="settings-description">
							Allow event viewers to contact you about your products and services (generate leads)
						</p>
					</div>
					<div className="settings-divider" />

					{/* Download All Photos Section */}

					{hasDownloadPin && (
						<>
							<div className="settings-section">
								<div className="settings-header">
									<div className="settings-title">Download / Send All Photos</div>
									<Button
										icon={<Download size={16} />}
										onClick={() => this.setState({ showDownloadForm: !showDownloadForm })}
									/>
								</div>
								<p className="settings-description">Send download link to clients/users</p>
								{showDownloadForm && (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											marginTop: '16px'
										}}>
										<Input
											type="email"
											value={emailInput}
											onChange={this.handleEmailChange}
											placeholder="Enter your email"
											style={{ marginRight: '8px', width: '240px' }}
										/>
										<Button
											type="primary"
											onClick={() => {
												const isValidEmail = this.validateEmail(emailInput);
												if (isValidEmail) {
													downloadAllPhotos(event.id, emailInput, shareDetail.downloadPin);
												}
											}}
											disabled={emailError}>
											Download
										</Button>
									</div>
								)}
							</div>
							<div className="settings-divider" />
						</>
					)}

					{/* Download Options Section */}
					<div className="settings-section">
						<DownloadOptionsCompact
							value={this.state.downloadOption}
							onChange={(e) => this.setState({ downloadOption: e.target.value })}
						/>
					</div>
				</div>

				<style jsx="true">{`
					.settings-section {
						width: 100%;
					}

					.settings-header {
						width: 100%;
						display: flex;
						flex-flow: row;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 8px;
					}

					.settings-title {
						font-size: 1rem;
						color: #2d3748;
						font-weight: 500;
					}

					.settings-description {
						color: #4a5568;
						font-size: 14px;
						margin-bottom: 0;
					}

					.settings-divider {
						width: 100%;
						height: 1px;
						background-color: #e2e8f0;
						margin: 8px 0;
					}

					.guest-upload-limit {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 12px;
					}

					.guest-upload-limit p {
						margin-bottom: 0;
						color: #4a5568;
						font-size: 14px;
					}

					.download-form {
						display: flex;
						flex-direction: column;
						gap: 8px;
						margin-top: 12px;
					}

					.error-message {
						color: #e53e3e;
						font-size: 12px;
						margin: 4px 0 0;
					}

					.share-copy {
						padding: 4px 8px;
						border-radius: 4px;
						transition: all 0.2s;
					}

					.share-copy.copied {
						background-color: #48bb78;
						color: white;
					}

					@media (max-width: 576px) {
						.download-form {
							flex-direction: column;
							align-items: stretch;
						}

						.download-form .ant-input {
							width: 100%;
							margin-right: 0;
							margin-bottom: 8px;
						}
					}
				`}</style>
			</Modal>
		);
	}
}

EventSettings.propTypes = {
	userId: PropTypes.string.isRequired,
	event: PropTypes.object.isRequired,
	updateEventBoolKey: PropTypes.func.isRequired,
	updateNewGuestMaxPhotos: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
	fetchShareDetail: PropTypes.func.isRequired,
	downloadAllPhotos: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		userId: state.auth.userId,
		event: state.pouchDB.visibleEvent
	};
}

const mapDispatchToProps = (dispatch) => ({
	fetchShareDetail: async (eventDocId) => {
		return await Event.fetchShareDetail(eventDocId);
	},

	updateEventBoolKey: async (userId, eventId, key, value) => {
		await Event.editEventBoolKey(userId, eventId, key, value);
		let event = await Event.fetchEvent(eventId);
		return await dispatch(refreshVisibleEvent(event));
	},

	updateNewGuestMaxPhotos: async (userId, eventId, value) => {
		await Event.editEventGuestMaxPhotos(userId, eventId, value);
		let event = await Event.fetchEvent(eventId);
		return await dispatch(refreshVisibleEvent(event));
	},

	downloadAllPhotos: async (eventId, email, pin) => {
		try {
			const result = await Event.downloadAllPhotos(eventId, email, pin);
			if (result.message) {
				message.error(result.message);
			} else {
				message.success(result);
			}
		} catch (err) {
			message.error('Something went wrong');
		}
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(EventSettings);
